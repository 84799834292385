export default {
  authentication: {
    slider: {
      item1Title: '',
      item1: 'Happening Now: Beyond Monitoring, Embrace Prevention, Anticipation, and Swift Action',
      item2: 'Industry Updates: ArcadianAI leads the way in proactive daycare safety.',
      item3:
        'Alarming Trend: Every six seconds, a business falls victim to retail crimes, internal theft, or robbery.',
      updateTwitter: 'X Updates',
      book: 'Book 1:1 Free Onboarding',
      askUs: 'How Revenue-Sharing Works'
    },
    headers: {
      title: 'Watch it.',
      subtitle: 'Your cellphone number is your key to entry! Enter it now to log in or sign up.',
      welcomeBack: 'Welcome Back!',
      enterPassword: 'Enter your password. ',
      passwordRecovery: 'Password Recovery'
    },
    signupFooter: {
      main:
        "By clicking '{data}', I am agreeing to Arcadian Orbit Inc.'s {services} " +
        'and {Policy}, and consenting to receive digital communication about my services.',
      TermsofServices: 'Terms of Services',
      PrivacyPolicy: 'Privacy Policy',
      phoneNumber: 'Phone Number',
      signup: 'Sign Up'
    },
    recoveryPassword: {
      passwordUpdate: 'Updated!',
      login: 'Log In',
      PasswordRecovery: 'Password Recovery',
      Password: 'New Password',
      goBackLogin: 'Cancel, Back to Log In',
      Confirm: 'Confirm'
    },
    multiFactorRecovery: {
      multiFactor: 'Multi-Factor',
      authRecovery: 'Authentication Recovery',
      enterDigits: 'Enter your 36-digit recovery code',
      actions: {
        viaSms: 'via new code to { phone }',
        viaOneTime: 'via one-time code to saved devices',
        viaDigits: 'via 36-digits recovery codes',
        cancel: 'Cancel, Back to Log In',
        help: 'Help',
        sendAgain: 'Send Again',
        recoverMFA: 'Recover MFA'
      },
      messages: {
        otpLabel: 'Enter the 6-digit code sent to your phone',
        otpError: 'Oops. Something went wrong.',
        passwordError: 'Oops. Something went wrong.',
        recovered: 'Authentication is recovered.',
        digitsTooltip:
          'Your unique recovery code, downloaded \n' +
          'during MFA activation, is vital for account recovery.',
        confirmIdentify:
          '    Confirm your identity by checking for a notification from XXXX Mobile on your trusted devices. Please be aware\n' +
          '          that this notification will only remain valid for 5 minutes.'
      },
      labels: {
        yourPassword: 'Your Password',
        recoveryCode: '36-digit recovery code'
      }
    },
    safeModeLabel: 'Keep me logged in for easy access.',
    socialLoginsDivider: 'OR continue with',
    OTPEnterCode: 'Enter the 6-digit code sent to your phone',
    TOTPEnterCode: 'Enter the 6-digit code sent to your {app}',
    authenticatorApp: 'Authenticator App',
    backToNumber: 'I have a new number',
    recoveryTotp: 'Recover Authentication',
    passwordForget: 'I forgot my password',
    newUserHeader: 'Welcome!',
    newUserDescription: 'One last step to sign you up.',
    countCamera: {
      title: ['Own', 'Cameras? *'],
      none: 'None',
      personal: '1 - 5',
      business: '5 - 10',
      enterprise: '10 - ∞'
    },
    cameraBrand: {
      title: 'Which brands?',
      subTitle: 'Select all that apply.',
      Arcadian: 'Arcadian',
      Uniview: 'Uniview',
      Hanwha: 'Hanwha',
      VivoTek: 'VivoTek',
      Sunell: 'Sunell',
      Axis: 'Axis',
      Bosch: 'Bosch',
      Hikvision: 'Hikvision',
      Honeywell: 'Honeywell',
      Dahua: 'Dahua',
      Other: 'Other'
    },
    sidebar: {
      title: 'Identity Verification',
      passwordInstead: 'Enter Password Instead',
      mfaInstead: 'Use Multi-Factor Auth. Instead',
      smsDescription:
        'To ensure your account stays secure, some actions need extra verification. Please enter the 6-digit code sent to your phone.',
      appDescription:
        'To ensure your account stays secure, some actions need extra verification. Please open your multi-factor Authenticator App and enter the generated code in the field below.',
      passwordDescription:
        'To ensure your account stays secure, some actions need extra verification. Please enter the 6-digit code sent to your phone.',
      alert: {
        title: 'Fun Fact',
        description:
          "You're stepping into the shoes of a SuperUser! This is what we call 'Admin' or 'Sudo' mode. It's like having a backstage pass at a rock concert – you get to see and do all the cool things most people can't! You can install new features, tweak some settings, and more. But remember, with your backstage pass comes responsibility, so be careful with your new found powers!"
      },
      actions: {
        recoverMFA: 'Recover Authentication',
        recoverPassword: 'Recover Password'
      }
    }
  },
  capacity: {
    title: 'Melting Servers 🥵',
    subTitle:
      'We’re expanding our infrastructure for more sign ups. {boldText} Leave your information to be the first to know.',
    boldText: 'We’ll be back up shortly.',
    email: 'Email *',
    btn: 'submit',
    successAlert: 'Thank you! You’ll be the first to know.'
  },
  authLabels: {
    email: 'Your Email',
    fullname: 'Your Fullname',
    internetProvider: 'Your Internet Provider',
    password: 'Set a Password'
  }
}
