import type {
  CreateCustomerSettingData,
  CreateCustomerSettingResponse,
  FindCustomSetting
} from './custom-setting.interfaces'
import { Service } from '../../service'
import { CustomSettingsType } from './custom-setting.interfaces'

export class CustomSettingService extends Service {
  // ***** TOUR APIS  *****
  public create(data: CreateCustomerSettingData) {
    return this.post<CreateCustomerSettingResponse>(`/api/custom-settings/custom-settings`, data)
  }
  public list(type: CustomSettingsType = CustomSettingsType.user) {
    return this.get<CreateCustomerSettingResponse[]>(`/api/custom-settings/custom-settings/${type}`)
  }
  public find(data: FindCustomSetting) {
    return this.post<CreateCustomerSettingResponse>(
      `/api/custom-settings/custom-settings/find`,
      data
    )
  }
  public remove(id: string) {
    return this.delete<void>(`/api/custom-settings/custom-settings/${id}`)
  }
}
