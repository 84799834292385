import type { PlayerCore } from '@/player/lib/player/player-core'
import type { DisplayCallbacks, PlayerPlaybackConfig, RGBBuffer } from '@/player/interfaces'
import { reactive } from 'vue'
import { PeriodType } from '@/player/types'
import * as Comlink from 'comlink'
import { MaskLocal } from '@/player/lib/mask/mask-local'
import { ImagePreview } from '@/player/lib/preview/image-preview'

function FileReaderPromise(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result as string)
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsDataURL(blob)
  })
}
export class DecoderImageDisplay {
  public canvas: HTMLCanvasElement | undefined
  height = 0
  width = 0

  public localMode = false
  public localConfig = reactive<PlayerPlaybackConfig>({
    lastFrameDate: 0,
    speed: 1,
    timelineSize: PeriodType.hourly,
    isLive: true,
    streamIndex: 0
  })
  public localSizeCallback?: (x: number, y: number) => void

  get config() {
    if (this.localMode) {
      return this.localConfig
    } else {
      return this.core.playback
    }
  }

  get worker() {
    if (this.localMode) {
      return this.core.preview.worker
    } else {
      return this.core.worker
    }
  }

  get renderHeight() {
    return this.height
  }

  get renderWidth() {
    return this.width
  }

  set renderHeight(value: number) {
    this.setRenderSize(this.width, value)
  }

  set renderWidth(value: number) {
    this.setRenderSize(value, this.height)
  }

  async setRenderSize(width: number, height: number) {
    this.width = width
    this.height = height
    if (this.worker) {
      await this.worker.setRenderSize(width, height)
    } else {
      console.log('setRenderSize', this.localMode, this.worker)
    }
  }

  constructor(public readonly core: PlayerCore) {}

  init() {
    return this.worker.setDisplayCallbacks(
      Comlink.proxy<DisplayCallbacks>({
        onTimeChanged: this.onTimeChanged.bind(this),
        onSizeChanged: this.onSizeChanged.bind(this)
      })
    )
  }

  setCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    const offscreen = canvas.transferControlToOffscreen()
    if (this.worker) {
      return this.worker.setDisplayCanvas(Comlink.transfer(offscreen, [offscreen]))
    } else {
      console.log('setDisplayCanvas', this.localMode, this.worker)
    }
  }

  destroy() {
    // todo
  }

  protected convertImageToDataURL(image: RGBBuffer, type: string) {
    const canvas = document.createElement('canvas')
    canvas.height = image.height
    canvas.width = image.width
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('cant create 2d context')
    const imageData = new ImageData(
      new Uint8ClampedArray(image.data),
      image.stride / 4,
      image.height
    )
    ctx.putImageData(imageData, 0, 0, 0, 0, canvas.width, canvas.height)
    return canvas.toDataURL(type)
  }

  async getScreenShot(format = 'image/png') {
    const image = await this.core.worker.getLastImage()
    if (image) {
      const canvas = document.createElement('canvas')
      canvas.height = image.codedHeight
      canvas.width = image.codedWidth
      const ctx = canvas.getContext('2d')
      if (ctx) {
        if (image.data instanceof ImageBitmap) {
          ctx.drawImage(
            image.data,
            0,
            0,
            image.data.width,
            image.data.height,
            0,
            0,
            canvas.width,
            canvas.height
          )
        } else if (image.data instanceof Uint8Array && image.stride) {
          const imageData = new ImageData(
            new Uint8ClampedArray(image.data),
            image.stride / 4,
            image.displayHeight
          )
          const data = await createImageBitmap(imageData)
          ctx.drawImage(data, 0, 0, data.width, data.height, 0, 0, canvas.width, canvas.height)
        } else {
          ctx.drawImage(
            image as unknown as ImageBitmap,
            0,
            0,
            image.codedWidth,
            image.codedHeight,
            0,
            0,
            canvas.width,
            canvas.height
          )
        }
        if (this.core.maskConfig.enabled && this.core.information.maskData) {
          const mask = new MaskLocal()
          mask.setCtx(ctx)
          mask.setRenderSize(canvas.width, canvas.height)
          mask.setMaskData(this.core.information.maskData)
          mask.draw()
        }
      }
      return canvas.toDataURL(format)
    } else {
      const preview = new ImagePreview(this.core.cameraId)
      if (this.core.maskConfig.enabled && this.core.information.maskData) {
        preview.maskData = this.core.information.maskData
      }
      return preview.live()
    }
  }

  protected onSizeChanged(width: number, height: number) {
    if (!this.localMode) {
      this.core.helpers.setAspectRatio(width, height)
    } else if (this.localSizeCallback) {
      this.localSizeCallback(width, height)
    }
  }

  protected onTimeChanged(time: number) {
    this.config.lastFrameDate = time
  }
}
