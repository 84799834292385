import { useServices } from '@/lib/services'
import { AnalyticEventTypes, WeekDaysEnum } from '@/lib/api'
import type { operationHours } from '@/lib/api'
import { AlertTypes, FrequencyTypes } from '@/lib/api/services/ai/alert/alert.interface'
import type {
  SchedulePeriodDto,
  ScheduleRoutineDto
} from '@/lib/api/services/ai/alert/alert.interface'

function getDayOfWeek(
  dayOfWeek: WeekDaysEnum,
  date = new Date(),
  timestamp: 'local' | 'utc' = 'local'
) {
  const currentDay = timestamp === 'local' ? date.getDay() : date.getUTCDay() // Get current day of the week (0-6)
  const difference = dayOfWeek - currentDay // Calculate difference to desired day
  const resultDate = new Date(date)
  timestamp === 'local'
    ? resultDate.setDate(resultDate.getDate() + difference)
    : resultDate.setUTCDate(resultDate.getUTCDate() + difference) // Set the new date by adding the difference
  return resultDate
}

function getDayStart() {
  const date = new Date()
  date.setUTCHours(0, 0, 0)
  return date
}

function getDayEnd() {
  const date = new Date()
  date.setUTCHours(23, 59, 59)
  return date
}

function generatePeriod(start: Date, end: Date): SchedulePeriodDto {
  return {
    startTime: {
      hour: start.getUTCHours(),
      minute: start.getUTCMinutes(),
      second: start.getUTCSeconds()
    },
    endTime: {
      hour: end.getUTCHours(),
      minute: end.getUTCMinutes(),
      second: end.getUTCSeconds()
    }
  } as SchedulePeriodDto
}

function getHoursMap(data: operationHours[]) {
  const hoursMap = new Map<WeekDaysEnum, Array<{ date: Date; type: 'from' | 'to' }>>()

  for (const oh of data) {
    if (!oh.close && oh.from && oh.to) {
      const from = new Date(getDayOfWeek(oh.dayName, new Date(oh.from)))
      const fromHours = hoursMap.get(from.getUTCDay()) || []
      fromHours.push({ date: from, type: 'from' })
      hoursMap.set(from.getUTCDay(), fromHours)

      const to = new Date(getDayOfWeek(oh.dayName, new Date(oh.to)))
      const toHours = hoursMap.get(to.getUTCDay()) || []
      toHours.push({ date: to, type: 'to' })
      hoursMap.set(to.getUTCDay(), toHours)
    }
  }
  return hoursMap
}

function convertOperationHours(
  data: operationHours[],
  notifyAfterHours = true
): Array<ScheduleRoutineDto> {
  if (!notifyAfterHours) return []
  const hours = new Array<ScheduleRoutineDto>()
  const hoursMap = getHoursMap(data)
  for (const day of [0, 1, 2, 3, 4, 5, 6]) {
    const periods: Array<SchedulePeriodDto> = []

    let dayHours = hoursMap.get(day)
    if (dayHours) {
      dayHours = dayHours.sort((a, b) => a.date.getTime() - b.date.getTime())
      let latestState: { date: Date; type: 'from' | 'to' } | undefined = undefined

      for (let i = 0; i < dayHours.length; i++) {
        const hour = dayHours[i]
        if (hour.type === 'from') {
          if (latestState) {
            // ignore
          } else {
            periods.push(generatePeriod(getDayStart(), hour.date))
          }
        } else {
          if (i + 1 < dayHours.length) {
            const nextHour = dayHours[i + 1]
            if (nextHour.type === 'from') {
              periods.push(generatePeriod(hour.date, nextHour.date))
            } else {
              const latestPeriod = periods.pop()
              if (latestPeriod) {
                latestPeriod.startTime = {
                  hour: hour.date.getUTCHours(),
                  minute: hour.date.getUTCMinutes(),
                  second: hour.date.getUTCSeconds()
                }
                periods.push(latestPeriod)
              }
            }
          } else {
            periods.push(generatePeriod(hour.date, getDayEnd()))
          }
        }
        latestState = hour
      }
      if (periods.length) {
        hours.push({
          periods,
          date: getDayOfWeek(day, undefined, 'utc').toISOString()
        })
        continue
      }
    }

    hours.push({
      periods: [
        {
          startTime: { hour: 0, minute: 0, second: 0 },
          endTime: { hour: 23, minute: 59, second: 59 }
        }
      ],
      date: getDayOfWeek(day, undefined, 'utc').toISOString()
    })
  }
  return hours
}

const fromDate = new Date()
fromDate.setHours(9)
fromDate.setMinutes(0)
fromDate.setSeconds(0)

const toDate = new Date()
toDate.setHours(17)
toDate.setMinutes(0)
toDate.setSeconds(0)

export const defaultOperationHours = [
  {
    close: true,
    dayName: 0,
    from: undefined,
    to: undefined
  },
  {
    close: false,
    dayName: 1,
    from: fromDate,
    to: toDate
  },
  {
    close: false,
    dayName: 2,
    from: fromDate,
    to: toDate
  },
  {
    close: false,
    dayName: 3,
    from: fromDate,
    to: toDate
  },
  {
    close: false,
    dayName: 4,
    from: fromDate,
    to: toDate
  },
  {
    close: false,
    dayName: 5,
    from: fromDate,
    to: toDate
  },
  {
    close: true,
    dayName: 6,
    from: undefined,
    to: undefined
  }
]
export const tamperingHours = [
  {
    close: true,
    dayName: 0,
    from: undefined,
    to: undefined
  },
  {
    close: true,
    dayName: 1,
    from: undefined,
    to: undefined
  },
  {
    close: true,
    dayName: 2,
    from: undefined,
    to: undefined
  },
  {
    close: true,
    dayName: 3,
    from: undefined,
    to: undefined
  },
  {
    close: true,
    dayName: 4,
    from: undefined,
    to: undefined
  },
  {
    close: true,
    dayName: 5,
    from: undefined,
    to: undefined
  },
  {
    close: true,
    dayName: 6,
    from: undefined,
    to: undefined
  }
]
const filters = [
  {
    eventType: AnalyticEventTypes.HwMotion,
    indexes: ['0', '-1']
  },
  {
    eventType: AnalyticEventTypes.HwAudio,
    indexes: ['0', '-1']
  },
  {
    eventType: AnalyticEventTypes.HwBorderCrossing,
    indexes: ['0', '-1']
  },
  {
    eventType: AnalyticEventTypes.SwMotion,
    indexes: ['0', '-1']
  }
]
const tamperingFilters = [
  {
    eventType: AnalyticEventTypes.HwTampering,
    indexes: ['0', '-1']
  }
]

export async function UpdateMainSchedule(data: operationHours[]) {
  const AlertService = useServices().ai.alert
  const TeamService = useServices().permissionManager.team
  const notifications = [AlertTypes.firebase, AlertTypes.email, AlertTypes.inApp]
  const teams = await TeamService.list()
  const teamIds = teams.map((t) => t.id)

  const Schs = await AlertService.getCurrentWorkspaceSchedules()
  const normalSch = Schs.find((r) =>
    r.filters.some((f) => f.eventType === AnalyticEventTypes.HwMotion)
  )
  const tamperingSch = Schs.find(
    (r) =>
      r.filters.some((f) => f.eventType === AnalyticEventTypes.HwTampering) &&
      r.filters.length === 1
  )
  if (!tamperingSch) {
    await AlertService.create({
      filters: tamperingFilters,
      teamIds: teamIds,
      notificationTypes: notifications,
      frequency: FrequencyTypes.weekly,
      routines: convertOperationHours(tamperingHours)
    })
  }
  if (!normalSch) {
    return AlertService.create({
      filters: filters,
      teamIds: teamIds,
      notificationTypes: notifications,
      frequency: FrequencyTypes.weekly,
      routines: convertOperationHours(data)
    })
  } else {
    const updateObject: any = {
      filters: filters,
      routines: convertOperationHours(data),
      frequency: FrequencyTypes.weekly
    }
    if (normalSch?.filters?.length !== filters.length) updateObject['filters'] = filters
    if (normalSch?.notificationTypes?.length !== notifications.length)
      updateObject['notificationTypes'] = notifications
    if (normalSch?.teamIds?.length !== teamIds.length) updateObject['teamIds'] = teamIds
    return AlertService.update(normalSch.id, updateObject)
  }
}
