import { watch } from 'vue'
import { PlayerCore } from '@/player/lib/player/player-core'
import { EventTypes } from '@/player/types'

export class PlayerWatchers {
  protected lastFrameHasAnalytic = false

  constructor(protected readonly core: PlayerCore) {
    // todo add unwatch
    this.cameraWatchers()
  }

  public cameraWatchers() {
    watch(this.core.detailedStatus, this.core.helpers.calculateStatus.bind(this.core.helpers))
    watch(this.core.size, this.core.helpers.setRenderSizes.bind(this.core.helpers))
    watch(
      [this.core.archiveMap, this.core.downMap],
      this.core.helpers.setArchiveMap.bind(this.core.helpers)
    )
    watch(this.core.bookmarks, this.core.helpers.setBookmark.bind(this.core.helpers))
    watch(
      () => this.core.information.maskData,
      this.core.maskHelper.onMaskUpdate.bind(this.core.maskHelper)
    )
    watch(
      [() => this.core.playback.timelineSize, this.core.size],
      this.core.analyticManager.watchAnalyticTimelineSize.bind(this.core.analyticManager),
      { immediate: true }
    )
    window.addEventListener(
      'resize',
      this.core.helpers.setRenderSizes.bind(this.core.helpers),
      false
    )
  }

  public onCaptureEvents(set: Set<EventTypes>) {
    if (set.has(EventTypes.NoFrame)) {
      // this.core.showInfo('This camera has no footage for this moment.')
    }
    if (set.has(EventTypes.ArchiveMapChanged)) {
      setTimeout(() => {
        // to give server enough time but should replace with websocket event
        this.core.apis.archiveMap()
        this.core.apis.downtimeMap()
      }, 500)
    }
    if (
      set.has(EventTypes.HWAudioDetected) ||
      set.has(EventTypes.HWMotionDetected) ||
      set.has(EventTypes.HWFaceDetected) ||
      set.has(EventTypes.HWSensor) ||
      set.has(EventTypes.HWBorderCrossed)
    ) {
      this.core.analyticManager.onSoftSeek()
      this.lastFrameHasAnalytic = true
    } else {
      if (this.lastFrameHasAnalytic) {
        this.lastFrameHasAnalytic = false
        setTimeout(() => {
          this.core.analyticManager.onSoftSeek()
        }, 5000)
      }
    }
    if (
      set.has(EventTypes.Connected) ||
      set.has(EventTypes.Disconnected) ||
      set.has(EventTypes.RecordFailed)
    ) {
      this.core.apis.detailedStatus()
    }
  }
}
