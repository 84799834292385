import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'
import { TimelineHelpers } from '@/player/lib/playback-timeline/timeline-helpers'
import { watch } from 'vue'

export class TimelinePeriodSelectorManager {
  public hasError = false
  get config() {
    return this.timelineCore.core.selectorData
  }

  constructor(public readonly timelineCore: TimelineCore) {
    watch(
      () => this.config,
      () => this.timelineCore.resetRenderCache()
    )
  }

  activateNormal() {
    return this.activate()
  }

  activeRecord() {
    return this.activate(this.timelineCore.view.now - 60_000, this.timelineCore.view.now)
  }

  activate(
    start = this.timelineCore.view.now - this.timelineCore.config.timelineSize,
    end = this.timelineCore.view.now + this.timelineCore.config.timelineSize,
    color = '#FFFFFF',
    description = ''
  ) {
    this.config.isActive = true
    this.config.color = color
    this.config.start = Math.min(Math.max(start, this.timelineCore.minSeek), Date.now())
    this.config.end = Math.min(Math.max(end, this.timelineCore.minSeek), Date.now())
    this.config.description = description
    this.config.startX = Math.floor(this.timelineCore.ctx.canvas.offsetLeft + start - 4)
    this.config.startY = Math.floor(this.timelineCore.ctx.canvas.offsetTop)
  }

  deactivate() {
    this.config.isActive = false
    this.config.start = 0
    this.config.end = 0
  }

  onDrag(dx: number, dy: number, x: number, y: number): string {
    let context = ''
    if (this.config.isActive && this.config.startPath && this.config.endPath) {
      if (this.timelineCore.ctx.isPointInPath(this.config.startPath, x, y)) {
        this.doDrag(dx, 'selector-start')
        context = 'selector-start'
      }
      if (this.timelineCore.ctx.isPointInPath(this.config.endPath, x, y)) {
        this.doDrag(dx, 'selector-end')
        context = 'selector-end'
      }
    }
    if (context !== '') {
      this.timelineCore.resetRenderCache()
    }
    return context
  }

  onHover(x: number, y: number) {
    if (this.config.isActive && this.config.startPath && this.config.endPath) {
      if (this.timelineCore.ctx.isPointInPath(this.config.startPath, x, y)) {
        this.timelineCore.view.cursor = 'col-resize'
      } else if (this.timelineCore.ctx.isPointInPath(this.config.endPath, x, y)) {
        this.timelineCore.view.cursor = 'col-resize'
      } else {
        this.timelineCore.view.cursor = 'pointer'
      }
    }
  }

  doDrag(dx: number, side: 'selector-start' | 'selector-end') {
    const diffX = (this.timelineCore.view.size * dx) / this.timelineCore.timelineRenderWidth
    if (side === 'selector-start') {
      const newStart = this.config.start + diffX
      if (newStart < this.config.end) {
        this.config.start = Math.min(Math.max(newStart, this.timelineCore.minSeek), Date.now())
      }
      return true
    } else if (side === 'selector-end') {
      const newEnd = this.config.end + diffX
      if (newEnd > this.config.start) {
        this.config.end = Math.min(Math.max(newEnd, this.timelineCore.minSeek), Date.now())
      }
      return true
    }
  }

  render() {
    if (this.config.isActive) {
      const start = this.timelineCore.findRelativeXForDate(this.config.start)
      const end = this.timelineCore.findRelativeXForDate(this.config.end)
      const color = this.hasError ? '#FF7A67' : this.config.color
      this.timelineCore.drawer.drawLine(start, this.timelineCore.renderPositionsConstant.selector, {
        ...this.timelineCore.renderConstant.selector,
        width: end - start,
        color: {
          fill: TimelineHelpers.formatColor(color, 0.5)
        }
      })
      this.config.startX = Math.floor(this.timelineCore.ctx.canvas.offsetLeft + start - 4)
      this.config.startY = Math.floor(this.timelineCore.ctx.canvas.offsetTop)
      this.timelineCore.drawer.drawLine(
        start - this.timelineCore.renderConstant.selectorHandler.width / 2,
        this.timelineCore.renderPositionsConstant.selectorHandler,
        {
          ...this.timelineCore.renderConstant.selectorHandler,
          color: {
            fill: color
          }
        }
      )
      this.timelineCore.drawer.drawLine(
        end - this.timelineCore.renderConstant.selectorHandler.width / 2,
        this.timelineCore.renderPositionsConstant.selectorHandler,
        {
          ...this.timelineCore.renderConstant.selectorHandler,
          color: {
            fill: color
          }
        }
      )
      this.config.startPath = this.timelineCore.drawer.drawLine(
        start - 13,
        this.timelineCore.renderPositionsConstant.selectorHandler,
        {
          width: this.timelineCore.renderConstant.selectorHandler.width + 18,
          height: this.timelineCore.renderConstant.selectorHandler.height,
          borderRadius: 0,
          shadow: undefined,
          color: {
            fill: 'rgba(255,255,255,0)'
          }
        }
      )
      this.config.endPath = this.timelineCore.drawer.drawLine(
        end - 13,
        this.timelineCore.renderPositionsConstant.selectorHandler,
        {
          width: this.timelineCore.renderConstant.selectorHandler.width + 18,
          height: this.timelineCore.renderConstant.selectorHandler.height,
          borderRadius: 0,
          shadow: undefined,
          color: {
            fill: 'rgba(255,255,255,0)'
          }
        }
      )
    }
  }
}
