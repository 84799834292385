import type { PlayerCore } from '@/player/lib/player/player-core'
import { InfoMode, PeriodType } from '@/player/types'
import { timelinePositionConst } from '@/player/lib/playback-timeline/timeline-consts'
import { CoverageTracker, EventCache } from '@/player/lib/events/event-cache'
import { AnalyticEventTypes } from '@/lib/api'
import Throttle from 'lodash/throttle'
import type { PlayerAnalyticConfig } from '@/player/interfaces'

export class AnalyticManager {
  public eventCache?: EventCache
  public coverageTracker?: CoverageTracker
  protected intervalHandle?: ReturnType<typeof setInterval>
  protected latestConfig: PlayerAnalyticConfig = {
    enabled: false,
    activeEventType: AnalyticEventTypes.None,
    activeIndex: ''
  }

  public onSoftSeek!: ReturnType<typeof Throttle<typeof AnalyticManager.prototype.onSeek>>

  constructor(protected readonly core: PlayerCore) {
    this.onSoftSeek = Throttle(this.onSeek.bind(this), 500)
  }

  get config() {
    return this.core.analyticConfig
  }
  disable() {
    if (this.config.enabled) {
      this.latestConfig = { ...this.config }
      this.config.enabled = false
      this.config.activeEventType = AnalyticEventTypes.None
      this.config.activeIndex = ''
      clearInterval(this.intervalHandle)
      if (this.core.timeline) {
        this.core.timeline.setCanvasRenderSize(
          this.core.timeline.renderWidth,
          timelinePositionConst[this.core.size.type].renderHeight
        )
      }
      if (this.latestConfig.activeEventType === AnalyticEventTypes.HwBorderCrossing) {
        this.core.crossLineConfig.enabled = false
      }
    }
  }

  enable(eventType: AnalyticEventTypes, index = '0') {
    if (
      !this.config.enabled ||
      this.config.activeEventType !== eventType ||
      this.config.activeIndex !== index
    ) {
      if (this.core.playback.timelineSize > PeriodType.quarter) {
        this.core.playback.timelineSize = PeriodType.singleMinute
      }
      if (
        this.core.size.type === 'large' &&
        (this.config.activeEventType !== eventType || this.config.activeIndex !== index)
      ) {
        this.config.enabled = true
        this.config.activeEventType = eventType
        this.config.activeIndex = index
        this.latestConfig = { ...this.config }
        this.intervalHandle = setInterval(this.onSoftSeek.bind(this), 60_000)
        this.eventCache = new EventCache(eventType + '.' + index)
        this.coverageTracker = new CoverageTracker()
        if (this.core.timeline) {
          this.core.timeline.analytics.recordsCache = this.eventCache
          this.core.timeline.setCanvasRenderSize(
            this.core.timeline.renderWidth,
            timelinePositionConst[this.core.size.type].renderHeightExtended
          )
          this.onSeek()
        }
        if (eventType === AnalyticEventTypes.HwAudio) {
          this.core.audioSetting.volume = 100
        }
        if (eventType === AnalyticEventTypes.HwBorderCrossing) {
          this.core.crossLineConfig.enabled = true
        }
      }
    }
  }

  public watchAnalyticTimelineSize() {
    if (this.core.playback.timelineSize > PeriodType.quarter || this.core.size.type !== 'large') {
      if (this.config.enabled) {
        this.core.showInfo(
          'To view AI-enabled events, stick to intervals of 15-min or less.',
          InfoMode.expand
        )
      }
      this.disable()
    } else {
      this.enable(this.latestConfig.activeEventType, this.latestConfig.activeIndex)
      this.onSoftSeek()
    }
  }

  protected async onSeek() {
    if (this.config.enabled && this.core.timeline && this.coverageTracker) {
      const start = this.core.timeline.view.start
      const end = this.core.timeline.view.end
      const reqs = this.coverageTracker.checkNeed(start, end)
      for (const req of reqs) {
        if (this.config.activeEventType) {
          this.coverageTracker.mark(req[0] as number, req[1] as number)
          const data = await this.core.apis.getEvents(
            new Date(req[0]),
            new Date(req[1]),
            this.config.activeEventType,
            this.config.activeIndex
          )
          if (this.eventCache) {
            if (
              this.eventCache.id ===
              this.config.activeEventType + '.' + this.config.activeIndex
            ) {
              this.eventCache.add(data as Array<[number, number]>)
            }
          }
        }
      }
    }
  }
}
