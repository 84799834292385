import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'
import { EventCache } from '@/player/lib/events/event-cache'

export class TimelineAnalytic {
  public recordsCache?: EventCache

  get records() {
    return this.recordsCache?.data || []
  }
  constructor(public readonly timelineCore: TimelineCore) {
    this.recordsCache = this.timelineCore.core.analyticManager.eventCache
  }

  push() {
    this.timelineCore.resetRenderCache()
  }

  getOverlappingPeriods(startTime: number, endTime: number): Array<[number, number]> {
    if (this.timelineCore.core.analyticConfig.enabled) {
      return this.records.filter(([start, end]) => start <= endTime && end >= startTime)
    } else return []
  }

  optimizeLineForDrawing(
    points: Array<[number, number]>,
    minValue: number
  ): Array<[number, number]> {
    if (points.length <= 1) return points
    const mergedLines: Array<[number, number]> = []
    let [currentStart, currentEnd] = points[0]
    for (let i = 1; i < points.length; i++) {
      const point = points[i]
      const [pointStart, pointEnd] = point

      if (pointStart - currentEnd <= 1) {
        currentEnd = pointEnd
      } else {
        mergedLines.push([currentStart, currentEnd])
        ;[currentStart, currentEnd] = point
      }
    }
    mergedLines.push([currentStart, currentEnd])
    if (mergedLines[0]) {
      const x = this.timelineCore.findRelativeXForDate(minValue)
      mergedLines[0][1] = Math.max(mergedLines[0][1], x)
    }
    return mergedLines
  }

  render(records: Array<[number, number]>) {
    if (this.timelineCore.core.analyticConfig.enabled) {
      for (const [start, end] of records) {
        this.timelineCore.drawer.drawLine(
          start,
          this.timelineCore.renderPositionsConstant.horizontalLine + 20,
          {
            ...this.timelineCore.renderConstant.horizontalLine,
            color: {
              fill: '#FFFFFF'
            },
            height: 18,
            width: end - start
          }
        )
      }
    }
  }

  onHover(x: number, y: number, haveBookmark = false) {}
}
