import type { Notification } from './'
import { Service } from '../../service'

export class NotificationService extends Service {
  public list(limit: string, offset: string) {
    return this.get<Notification[]>(`/api/notifier/notification?limit=${limit}&offset=${offset}`)
  }

  public find(id: string) {
    return this.get<Notification>(`/api/notifier/notification/${id}`)
  }

  public markAsRead(id: string) {
    return this.patch<Notification[]>(`/api/notifier/notification/${id}`)
  }

  public unread(limit: string, offset: string) {
    return this.get<Notification[]>(
      `/api/notifier/notification/unread?limit=${limit}&offset=${offset}`
    )
  }
  public beforeSwitch(limit: string, offset: string) {
    return this.get<Notification[]>(
      `/api/notifier/notification/user?limit=${limit}&offset=${offset}`
    )
  }
}
