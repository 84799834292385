import { PlayerCore } from '@/player/lib/player/player-core'
import { EXPORT_BOX_HEIGHT, FunctionMode, InfoMode, PeriodType } from '@/player/types'
import { CanvasMerger } from '@/player/lib/snapshot/canvas-merger'
import { usePermissionManager } from '@/modules/Permissions'

// todo need refactor
export class KeyboardShortcutsHelper {
  protected keySet = new Set<string>()

  constructor(protected readonly core: PlayerCore) {
    this.resetSetBind = this.resetSet.bind(this)
    window.addEventListener('keyup', this.resetSetBind, { passive: true })
  }

  destroy() {
    window.removeEventListener('keyup', this.resetSetBind)
  }

  async onKeyPress(keyName: string) {
    this.keySet.add(keyName)
    if (keyName === 'ESCAPE') {
      return this.deactivateCurrentState()
    }
    if (keyName === 'ARROWLEFT') {
      if (!this.core.timeline) {
        this.setArchiveMode()
      }
      const controller = this.core.timeline || this.core.capture
      controller.seek(this.core.playback.lastFrameDate - 15000)
      this.core.capture.speed(1)
      this.core.showInfo('15 Sec', InfoMode.jumpBack)
    }
    if (keyName === 'ARROWRIGHT') {
      if (!this.core.timeline) {
        this.setArchiveMode()
      }
      const controller = this.core.timeline || this.core.capture
      controller.seek(this.core.playback.lastFrameDate + 15000)
      this.core.capture.speed(1)
      this.core.showInfo('15 Sec', InfoMode.jumpForward)
    }
    if (keyName === 'ARROWDOWN') {
      this.core.playback.timelineSize = this.transferTimelineSize(
        Math.abs(this.getTimeLineSize() - (1 % 6))
      )
    }
    if (keyName === 'ARROWUP') {
      this.core.playback.timelineSize = this.transferTimelineSize(
        Math.abs(this.getTimeLineSize() + (1 % 6))
      )
    }
    if (keyName === ' ') {
      // space
      if (this.core.mode.value === FunctionMode.liveMode) {
        this.setArchiveMode()
        setTimeout(() => {
          this.core.capture.speed(0)
        }, 100)
      } else {
        if (this.core.playback.speed === 0) {
          this.core.capture.speed(1)
        } else {
          this.core.capture.speed(0)
        }
      }
    }
    if (this.keySet.has('ALT')) {
      const permissionManager = usePermissionManager()
      if (keyName === 'S' && permissionManager.hasPermission({ action: 'cameraCreateSnapshot' })) {
        const merger = new CanvasMerger()
        await merger.addImagLayer(await this.core.display.getScreenShot())
        const result = merger.getImage()
        this.downloadImage(result)
        await this.core.apis.createSnapshot({
          file: result,
          time: new Date(this.core.playback.lastFrameDate)
        })
        // todo add toaster for save
      }
      if (keyName === 'C' && permissionManager.hasPermission({ action: 'cameraUpdateSettings' })) {
        if (this.core.emitFunction) {
          this.core.emitFunction('profileRequested', this.core.information)
        }
      }
      if (keyName === '+' || keyName === '=') {
        if (!this.core.zoomConfig.enabled) {
          this.core.digitalZoomController.enable()
        } else {
          this.core.digitalZoomController.zoomIn()
        }
      }
      if (keyName === '-' || keyName === '_') {
        this.core.digitalZoomController.zoomOut()
      }
      if (this.keySet.has('CONTROL')) {
        if (keyName === 'E' && permissionManager.hasPermission({ action: 'cameraCreateExport' })) {
          if (!this.core.information.maskData || this.core.information.maskData.length < 2) {
            if (this.core.size.type === 'large') {
              if (window.outerHeight >= EXPORT_BOX_HEIGHT) {
                if (!this.core.isExportMode.value) {
                  this.core.timeline.setPeriodType(PeriodType.singleMinute)
                  this.core.timeline.selector.activeRecord()
                  this.core.isExportMode.value = true
                }
              } else {
                this.core.showInfo(
                  'Experience restricted on small screens; switch to a laptop/desktop for all-out awesomeness.',
                  InfoMode.expand
                )
              }
            } else {
              this.core.showInfo('Expand for an immersive experience', InfoMode.expand)
            }
          }
        }
      }
    }
    if (this.keySet.has('CONTROL')) {
      const permissionManager = usePermissionManager()
      if (keyName === 'B' && permissionManager.hasPermission({ action: 'cameraCreateBookmark' })) {
        if (this.core.size.type === 'large') {
          if (!this.core.isNewBookmark.value) {
            this.core.timeline.selector.activate()
            this.core.isNewBookmark.value = true
          }
        } else {
          this.core.showInfo('Expand for an immersive experience', InfoMode.expand)
        }
      }
      if (keyName === 'M' && permissionManager.hasPermission({ action: 'privacyMaskUpdate' })) {
        if (!this.core.maskConfig.editMode) {
          this.core.maskHelper.disable()
        }
      }
    }
  }

  protected resetSetBind: (e: KeyboardEvent) => void

  protected resetSet(e: KeyboardEvent) {
    this.keySet.delete(e.key.toUpperCase())
  }

  protected deactivateCurrentState() {
    console.log('deactivateCurrentState')
    if (this.core.zoomConfig.enabled) {
      this.core.digitalZoomController.disable()
    }
    if (
      [
        FunctionMode.aiDetectionMode,
        FunctionMode.aiHeatmapMode,
        FunctionMode.historyMode,
        FunctionMode.snapshotMode
      ].includes(this.core.mode.value)
    ) {
      this.core.helpers.goLive()
    }
    if (this.core.isExportMode) {
      this.core.timeline?.selector.deactivate()
      this.core.isExportMode.value = false
      this.core.timeline.selector.hasError = false
    }
    if (this.core.isNewBookmark.value) {
      this.core.timeline.selector.deactivate()
      this.core.isNewBookmark.value = false
    }
    if (this.core.analyticConfig.enabled) {
      this.core.analyticManager.disable()
    }
    if (this.core.maskConfig.editMode) {
      this.core.maskConfig.editMode = false
    }
  }

  protected getTimeLineSize() {
    switch (this.core.playback.timelineSize) {
      case PeriodType.singleMinute:
        return 0
      case PeriodType.minimal:
        return 1
      case PeriodType.quarter:
        return 2
      case PeriodType.hourly:
        return 3
      case PeriodType.daily:
        return 4
      case PeriodType.monthly:
        return 5
      case PeriodType.threeMonth:
        return 6
      default:
        return 6
    }
  }

  protected transferTimelineSize(value: number) {
    switch (value) {
      case 0:
        return PeriodType.singleMinute
      case 1:
        return PeriodType.minimal
      case 2:
        return PeriodType.quarter
      case 3:
        return PeriodType.hourly
      case 4:
        return PeriodType.daily
      case 5:
        return PeriodType.monthly
      case 6:
        return PeriodType.threeMonth
      default:
        return PeriodType.threeMonth
    }
  }

  protected setArchiveMode() {
    if (this.core.playback.isLive || this.core.mode.value === FunctionMode.liveMode) {
      this.core.mode.value = FunctionMode.historyMode
      this.core.capture.archive(this.core.playback.lastFrameDate)
      this.core.decoderQueue.disable()
    }
  }

  protected downloadImage(data: string) {
    const link = document.createElement('a')
    link.download = `${this.core.information.name}-${new Date(
      this.core.playback.lastFrameDate
    ).toLocaleString()}.jpg`
    link.href = data
    link.click()
  }
}
