import {
  AnnotationObjectType,
  CameraStatus,
  CaptureStatus,
  Codec,
  EventTypes,
  FrameTypes,
  HeatMapPeriodType,
  InfoMode,
  PathActionType,
  PeriodType,
  SizeMode,
  StreamQuality
} from '@/player/types'
import type { Camera as ApiCamera, CrossLineConfig } from '@/lib/api'
import type { ObjectEmitsOptions, UnwrapNestedRefs } from 'vue'
import { Buffer } from 'buffer'
import type { YUVBuffer } from 'yuv-buffer'
import type { HeatMapRenderFactory } from '@/player/lib/heatmap/heatmap-render-factory'
import * as Comlink from 'comlink'
import { AnalyticEventTypes, CameraDownReason, CameraHealthOverall } from '@/lib/api'

export type CameraObject = ApiCamera
export type CameraInformation = UnwrapNestedRefs<CameraObject>
export type TimelineConfig = UnwrapNestedRefs<PlayerPlaybackConfig>
export type ArchiveMapEntity = [number, number, number] // [start, end, isRecording]
export type ArchiveMap = Array<ArchiveMapEntity>

export type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never
export type EmitFn<
  Options = ObjectEmitsOptions,
  Event extends keyof Options = keyof Options
> = Options extends Array<infer V>
  ? (event: V, ...args: any[]) => void
  : {} extends Options
  ? (event: string, ...args: any[]) => void
  : UnionToIntersection<
      {
        [key in Event]: Options[key] extends (...args: infer Args) => any
          ? (event: key, ...args: Args) => void
          : (event: key, ...args: any[]) => void
      }[Event]
    >

export interface PlayerSizeConfig {
  type: 'small' | 'medium' | 'large'
  mode: SizeMode
  desiredHeight: number | undefined | null
  desiredWidth: number | undefined | null
  renderHeight: number
  renderWidth: number
  wrapperHeight: number
  wrapperWidth: number
  layoutIndex?: number
}

export interface PlayerStreamQuality {
  quality: StreamQuality
  isAuto: boolean
}

export interface PlayerPlaybackConfig {
  lastFrameDate: number
  speed: number
  timelineSize: PeriodType
  isLive: boolean
  streamIndex: number
}

export interface PlayerHeatmapConfig {
  opacity: number
  timelineSize: HeatMapPeriodType
  activeImage: HeatMapRecordInfo | undefined
  displayFlipper: boolean
}

export interface PlayerZoomConfig {
  enabled: boolean
  level: number
  origin: {
    x: number
    y: number
  }
}

export interface PlayerAudioSetting {
  enabled: boolean
  volume: number
  lastCodec: Codec
}

export interface PlayerMaskConfig {
  enabled: boolean
  editMode: boolean
}

export interface PlayerAnalyticConfig {
  enabled: boolean
  activeEventType: AnalyticEventTypes
  activeIndex: string
}

export interface PlayerCrossLineConfig {
  enabled: boolean
  item: CrossLineConfig
}

export interface HeatMapRecordInfo {
  type: HeatMapPeriodType
  period: [number, number]
  image: string
}

export interface CameraDetailedStatus {
  enabled: boolean
  connecting: boolean
  connected: boolean
  recording: boolean
  analysing: boolean
  liveHighResStreamError: boolean
  liveLowResStreamError: boolean
  livePoorStreamError: boolean
  recordStreamError: boolean
  analyticStreamError: boolean
  program: boolean
  alarm: boolean
  recordFailed: boolean
  standBy: boolean
  streaming: boolean
  overall?: CameraHealthOverall
}

export interface CameraStreamInformation {
  streamCount: number
  highResIndex: number
  lowResIndex: number
  poorIndex: number
  recordIndex: number
  analyticIndex: number
}

export interface EventObject {
  type: FrameTypes.EventDetails
  date: number
  data: Buffer
  set: Set<EventTypes>
  isKey: boolean
}

export interface FrameObject {
  type: FrameTypes.AudioFrame | FrameTypes.VideoFrame
  date: number
  data: Buffer
  streamIndex: number
  isKey: boolean
}

export interface HeaderObject {
  type: FrameTypes.AudioHeader | FrameTypes.VideoHeader
  date: number
  data: Buffer | null
  streamIndex: number
  streamCodec: Codec
  isKey: boolean
}

export type CaptureObject = EventObject | FrameObject | HeaderObject

export interface DrawConfigShadow {
  blur: number
  offsetX: number
  offsetY: number
  color: string
}

export interface DrawConfigText {
  family: string
  weight: number
  size: number
  lineHeight: number
  color: string
}

export interface DrawConfigColor {
  fill?: string | CanvasPattern
  stroke?: string
}

export interface DrawConfigRectangle {
  x1: number
  y1: number
  x2: number
  y2: number
  lineWidth?: number
  borderRadius?: number
  color: DrawConfigColor
  shadow?: DrawConfigShadow
}

export interface DrawConfigCircle {
  x: number
  y: number
  radius: number
  lineWidth?: number
  color: DrawConfigColor
  shadow?: DrawConfigShadow
}

export interface DrawConfigPath {
  points: Array<{
    x: number
    y: number
    type: PathActionType
  }>
  lineWidth?: number
  color: DrawConfigColor
  shadow?: DrawConfigShadow
}

export interface DrawConfigAnnotationLabel extends DrawConfigText {
  backgroundColor: string
  paddingX: number
  paddingY: number
  radius: number
  content?: string
  shadow?: DrawConfigShadow
  position?: {
    top?: boolean
    bottom?: boolean
    right?: boolean
    left?: boolean
  }
}

export interface AnnotationRectangleObject {
  type: AnnotationObjectType.rectangle
  annotation: DrawConfigRectangle
  label?: DrawConfigAnnotationLabel
}

export interface AnnotationCircleObject {
  type: AnnotationObjectType.circle
  annotation: DrawConfigCircle
  label?: DrawConfigAnnotationLabel
}

export interface AnnotationPathObject {
  type: AnnotationObjectType.path
  annotation: DrawConfigPath
  label?: DrawConfigAnnotationLabel
}

export type DrawConfigObject = DrawConfigPath | DrawConfigCircle | DrawConfigRectangle
export type AnnotationObject =
  | AnnotationRectangleObject
  | AnnotationCircleObject
  | AnnotationPathObject

export interface DecodedAudioBuffer {
  sampleRate: number
  channels: Array<Float32Array>
}

export declare class NativeDecoder {
  public isBooted: boolean

  boot(codec: Codec): Promise<void>

  decode(data: Uint8Array): Promise<Array<YUVBuffer>>

  destroy(): void
}

export type DecoderConstructor = new () => NativeDecoder
export type HeatMapFactoryConstructor = new () => HeatMapRenderFactory

export type RGBColor = [number, number, number, number]

export interface HeatMapOptions {
  actualInput: ImageData
  colors: Array<RGBColor>
  opacity: number
  userMin?: number
  userMax?: number
}

export interface DrawConfigLineOpen {
  height: number
  width: number
  borderRadius: number
  shadow?: DrawConfigShadow
  color: DrawConfigColor
}

export interface DrawConfigCircleOpen {
  radius: number
  shadow?: DrawConfigShadow
  color: DrawConfigColor
}

export interface DrawConfigCenterLine {
  line: DrawConfigLineOpen
  circle: DrawConfigCircleOpen
}

export interface DrawConfigHeatmapTimeline {
  periodSize: number
  font: DrawConfigText
  horizontalLine: DrawConfigLineOpen
  centerLine: DrawConfigCenterLine
}

export interface Bookmark {
  id: string
  period: [number, number]
  color: string
  submitBy: string
  submitById: string
  description: string
}

export interface BookmarkWithX extends Bookmark {
  periodX: [number, number]
}

export interface DrawConfigBadge {
  color: string
  paddingX: number
  paddingY: number
  borderRadius: number
  font: DrawConfigText
}

export interface DrawConfigTimeline {
  centerLine: DrawConfigCenterLine
  horizontalLinePlaceHolder: DrawConfigLineOpen
  horizontalLine: DrawConfigLineOpen
  selector: DrawConfigLineOpen
  selectorHandler: DrawConfigLineOpen
  bookmark: DrawConfigLineOpen
  periodSize: number
  periodLine: DrawConfigLineOpen
  simiPeriodCount: number
  simiPeriodLine: DrawConfigLineOpen
  font: DrawConfigText
  dateLabel: DrawConfigBadge
}

export interface DrawConfigTimelinePositions {
  renderHeight: number
  renderHeightExtended: number
  horizontalLinePlaceHolder: number
  horizontalLine: number
  centerLine: number
  font: number
  label: number
  periodLine: number
  simiPeriodLine: number
  dateLabel: number
  bookmark: number
  selector: number
  selectorHandler: number
}

export interface SelectorConfig {
  isActive: boolean
  start: number
  end: number
  color: string
  startX: number
  startY: number
  startPath: undefined | Path2D
  endPath: undefined | Path2D
  description: string
}

export enum BookmarkType {
  userMade,
  AIEvent,
  RecordPaused
}
export interface ActiveBookmark {
  enabled: boolean
  x: number
  y: number
  bookmark: Bookmark | undefined
  type: BookmarkType
}

export interface ImageObject<T = RGBBuffer> {
  image?: T
  date: number
  windowDate: number
  streamIndex: number
}

export interface InfoMessage {
  at: number
  message: string
  mode: InfoMode
  show: boolean
}

export interface PreviewConfig {
  x: number
  y: number
  date: number
  enabled: boolean
}

export interface snapshotStyleConfig {
  color: string
  size: number
  eraser: boolean
}

export type PlayerCamerasStatus = Record<string, CameraStatus>
export type PlayerOverallStatus = Record<CameraStatus, number>

export type DecoderType =
  | 'native-hardware'
  | 'native-software'
  | 'mjpeg'
  | 'webassembly-rgb'
  | 'webassembly-yuv'

export interface StatisticalReport {
  dropKeyFrame: number
  dropFrame: number
  dropImage: number
  imageQueueFPS: number
  decodeAverage: number
  decoderError: number
  displayAverage: number
  displayError: number
  socketError: number
  bitrate: number
  frameQueueSizeAverage: number
  imageQueueSizeAverage: number
  codec: string
  colorFormat: string
  displaySize: string
  totalFrameDecoded: number
  totalKeyFrameDecoded: number
  heapSize: number
  imageBufferLatency: number
  decoderType: DecoderType
}

export interface CaptureCallbacks {
  onEvent: (ev: Set<EventTypes>) => unknown
  getURL: () => Promise<string>
  onStatusChange: (value: CaptureStatus) => unknown
}

export interface ImageQueueCallbacks {
  onLoadingChanged: (value: boolean) => unknown
  onFirstImageShownChanged?: (value: boolean) => unknown
}

export interface DisplayCallbacks {
  onSizeChanged: (w: number, h: number) => void
  onTimeChanged?: (time: number) => void
}
export interface AudioPlayerCallback {
  onAudio: (frame: CaptureObject) => unknown
}

export type MainWorkerCallback<T extends {}> = ReturnType<typeof Comlink.proxy<T>>

export interface RGBBuffer {
  width: number
  height: number
  actualWidth: number
  actualHeight: number
  format: string
  stride: number
  data: Uint8Array
}

export interface ImageBase {
  codedHeight: number

  codedWidth: number

  displayHeight: number

  displayWidth: number

  timestamp: number

  format: string

  stride?: number

  data?: undefined | YUVBuffer | Uint8Array | ImageBitmap
}

export class RGBImage implements ImageBase {
  codedHeight: number = 0
  codedWidth: number = 0
  displayHeight: number = 0
  displayWidth: number = 0
  timestamp: number = 0
  format: string = 'rgba'
  stride: number = 0
  data: Uint8Array | undefined = undefined
}

export declare class PlayerWorkerCore {
  setQueueCallbacks(callbacks: MainWorkerCallback<ImageQueueCallbacks>): void

  setDisplayCallbacks(callbacks: MainWorkerCallback<DisplayCallbacks>): void

  setCaptureCallbacks(callbacks: MainWorkerCallback<CaptureCallbacks>): void

  getState(): StatisticalReport

  enableState(): void

  disableState(): void

  isStateEnabled(): boolean

  initializeCapture(streamIndex: number): void

  initializeCaptureArchive(date: number): void

  archive(value: number): void

  live(streamIndex: number): void

  ptz(pan: number, tilt: number, zoom: number): void

  seek(value: number): void

  speed(value: number): void

  disableBuffering(): void

  enableBuffering(): void

  isBufferingEnabled(): boolean

  setRenderSize(width: number, height: number): void

  setDisplayCanvas(canvas: OffscreenCanvas): void

  getLastImage(): undefined | ImageBase

  disableLogger(): void

  enableLogger(): void

  directDecode(frame: HeaderObject): Promise<YUVBuffer>

  setAudioCallback(callbacks: MainWorkerCallback<AudioPlayerCallback>)

  disableAudioCallback(): void

  enableAudioCallback(): void

  setMaskData(data: Array<[number, number]>): void

  clearMask(): void
}

export type PlayerWorkerCoreConstructor = new () => PlayerWorkerCore

export interface PlayerParams {
  archiveMode?: boolean
  noLayout?: boolean
  initState?: PlayerInitStateConfig
}

export interface LayoutBoxConfig {
  id: string
  height?: number
  width?: number
  scaleMode?: 'vertical' | 'horizontal' | 'contain'
}

export type DowntimeMapPLayerEntity = {
  id: string
  startAt: number
  endAt: number
  reason: CameraDownReason
  finished: boolean
  recordStopper: string | undefined
}

export interface PlayerInitStateConfig {
  date?: number // no date mean live
  analytic?: number
}
