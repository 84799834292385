import { Service } from '../service'

export interface sessionSocketToken {
  token: string
}
export interface SessionSocketStatus {
  active: boolean
}
export interface UserSessionStatus {
  connected: boolean
}
export class UserEventBus extends Service {
  public getSocketToken() {
    return this.post<sessionSocketToken>(`/api/events/authentication/token`)
  }

  public checkUserSessionStatus() {
    return this.get<SessionSocketStatus>(`/api/events/session/is-active`)
  }
  public checkSocketStatus() {
    return this.get<UserSessionStatus>(`/api/events/session/is-connected`)
  }
}
