import { defineStore } from 'pinia'
import type { TagEachResource, TagResourceResponse } from '@/lib/api'
import { useServices } from '@/lib/services'

type CameraStateManagement = {
  tags: TagEachResource[]
  resourceWorkspace: TagResourceResponse | null
  tempWorkspaceSelectedTags: TagEachResource[]
}
export const useTagStore = defineStore('TagStore', {
  state: (): CameraStateManagement => ({
    tags: [],
    resourceWorkspace: null,
    tempWorkspaceSelectedTags: []
  }),
  getters: {
    cameraTags(): TagEachResource[] {
      return this.tags.filter((tag) => tag.scope === 'camera')
    },
    workspaceTags(): TagEachResource[] {
      return this.tags.filter((tag) => tag.scope === 'workspace') || []
    },
    workspaceSelectedTags(): TagEachResource[] {
      const selectedTags: TagEachResource[] = [...this.tempWorkspaceSelectedTags]
      return selectedTags
    },
    filterdWorkspaceTags(): TagEachResource[] {
      return useTagStore().workspaceTags.filter((tag) => {
        return !this.workspaceSelectedTags.some((selected) => tag.id === selected.id)
      })
    },
    isWorkspaceTagsChanged(): boolean {
      if (this.resourceWorkspace) {
        return (
          this.workspaceSelectedTags.length != this.resourceWorkspace.tags.length ||
          !this.workspaceSelectedTags.every((selected) =>
            this.resourceWorkspace?.tags.some((tag) => tag.id === selected.id)
          )
        )
      }
      return false
    }
  },
  actions: {
    async loadTags() {
      this.tags = await useServices().tagManager.tag.list()
    },
    async loadWorkspaceSelectedTags(id: string, force: boolean = false) {
      if (!this.resourceWorkspace || force) {
        const resourceWorkspace = await useServices()
          .tagManager.resource.findResource({
            remoteId: id,
            type: 'w'
          })
          .then((tags) => tags[0])
        if (resourceWorkspace && resourceWorkspace.tags) {
          this.resourceWorkspace = resourceWorkspace
          this.cloneTagsFromResource(resourceWorkspace.tags)
        }
      }
    },
    cloneTagsFromResource(tagResource: TagEachResource[]) {
      this.selectTag(
        tagResource.filter(
          (tag) => !this.tempWorkspaceSelectedTags.some((tempTag) => tempTag.id === tag.id)
        )
      )
    },
    selectTag(tags: TagEachResource[]) {
      this.tempWorkspaceSelectedTags = tags
    },

    async updateTags() {
      const resourceWorkspace = this.resourceWorkspace
      if (resourceWorkspace) {
        const addedTag = this.workspaceSelectedTags.filter(
          (itemB) => !resourceWorkspace.tags.some((itemA) => itemA.id === itemB.id)
        )
        const deletedTag =
          this.resourceWorkspace?.tags.filter(
            (itemA) => !this.workspaceSelectedTags.some((itemB) => itemB.id === itemA.id)
          ) || []

        if (addedTag.length > 0) {
          await this.assignTag(addedTag)
        }
        if (deletedTag.length > 0) {
          await this.dropTag(deletedTag)
        }
      } else {
        throw { getResource: true }
      }
    },

    async assignTag(tags: TagEachResource[]) {
      if (tags && tags.length > 0 && this.resourceWorkspace?.id) {
        const reqs = new Array<Promise<any>>()
        for (const tag of tags) {
          reqs.push(
            useServices().tagManager.resource.assignResource({
              tagId: tag.id,
              resourceId: this.resourceWorkspace.id
            })
          )
        }
        await Promise.all(reqs)
        this.resourceWorkspace.tags.push(...tags)
      }
    },

    async dropTag(tags: TagEachResource[]) {
      if (tags && tags.length > 0 && this.resourceWorkspace?.id) {
        const reqs = new Array<Promise<any>>()
        for (const tag of tags) {
          reqs.push(
            useServices().tagManager.resource.dropResource({
              tagId: tag.id,
              resourceId: this.resourceWorkspace.id
            })
          )
        }
        await Promise.all(reqs)
        this.resourceWorkspace.tags = this.resourceWorkspace.tags.filter(
          (tag) => !tags.some((removed) => removed.id === tag.id)
        )
      }
    },
    reset() {
      this.tags = []
      this.resourceWorkspace = null
      this.tempWorkspaceSelectedTags = []
    }
  }
})
