import type { PlayerCore } from '@/player/lib/player/player-core'
import { AnalyticEventTypes } from '@/lib/api'

export class EventCacheTampering {
  public data: Array<number[]> = []
  protected records: Array<number[]> = []
  private minDate: number = Infinity
  constructor(private readonly core: PlayerCore) {}

  setMinDate(value: number) {
    if (value < this.minDate) {
      this.minDate = value
      this.fetchAllEvents()
    }
  }

  private async fetchAllEvents(): Promise<void> {
    const now = Date.now()
    const twelveHours = 12 * 60 * 60 * 1000
    let currentDate = now

    while (currentDate > this.minDate) {
      const start = Math.max(currentDate - twelveHours, this.minDate)
      const end = currentDate
      const events = await this.core.apis.getEvents(
        new Date(start),
        new Date(end),
        AnalyticEventTypes.HwTampering
      )
      this.add(events)
      currentDate = start
    }
  }

  public add(data: Array<number[]>): void {
    if (data.length > 0) {
      this.mergeData(data)
      this.flattenData()
      this.makeData()
    }
  }

  protected makeData() {
    const result: Array<number[]> = []
    if (this.records.length > 1) {
      for (let i = 0; i < this.records.length; i++) {
        const [date, status] = this.records[i]
        if (status === 1) {
          if (i + 1 < this.records.length) {
            result.push([date, this.records[i + 1][0]])
          }
        }
      }
    }
    this.data = result
  }

  protected flattenData() {
    if (this.records.length > 1) {
      const result: Array<number[]> = [this.records[0]]
      let lastState = this.records[0][1]
      for (let i = 1; i < this.records.length; i++) {
        const state = this.records[i][1]
        if (state !== lastState) {
          result.push(this.records[i])
          lastState = state
        }
      }
      this.records = result
    }
  }

  protected mergeData(newData: Array<number[]>): void {
    this.records.push(...newData)
    this.records.sort((a, b) => a[0] - b[0])
  }
}
