import { PlayerCore } from '@/player/lib/player/player-core'
import { SnapshotPainter } from '@/player/lib/snapshot/snapshot-painter'
import { TimelineCore } from '@/player/lib/playback-timeline/timeline-core'
import { CaptureClient } from '@/player/lib/capture/capture-client'
import { STREAM_PROXY_ADDRESS } from '@/player/lib/player/player-helpers'
import { HeatMapDisplay } from '@/player/lib/heatmap/heatmap-display'
import { PlayerPreview } from '@/player/lib/preview/player-preview'
import { HeatmapTimeline } from '@/player/lib/heatmap-timeline/heatmap-timeline'
import { Annotator } from '@/player/lib/annotator/annotator'
import type { PlayerInitStateConfig, PlayerParams } from '@/player/interfaces'
import { FunctionMode } from '@/player/types'
import { AnalyticEventTypes, convertEventType } from '@/lib/api'

export class InitializeHelper {
  constructor(public readonly core: PlayerCore) {}

  async initialize(params: PlayerParams) {
    await Promise.all([
      this.core.decoderQueue.init(),
      this.core.display.init(),
      this.core.audioPlayer.init(),
      this.initializeRequiredAPIs()
    ])
    await this.core.maskHelper.enable()
    await this.initializeCapture(params)
    this.goToInitState(params.initState)
    if (params.noLayout) {
      this.core.setActivePlayer()
    }
  }

  initializeRequiredAPIs() {
    return Promise.all([
      this.core.apis.archiveMap(),
      this.core.apis.downtimeMap(),
      this.core.apis.detailedStatus(),
      this.core.apis.bookmarkList(),
      this.core.apis.supportPTZ(),
      this.core.apis.crossLine()
    ])
  }

  async initializeDisplay(canvas: HTMLCanvasElement) {
    await this.core.display.setCanvas(canvas)
    await this.core.display.setRenderSize(this.core.size.renderWidth, this.core.size.renderHeight)
  }

  initializeSnapshot(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('no canvas ctx!')
    if (!this.core.snapshotPainter) {
      this.core.snapshotPainter = new SnapshotPainter(ctx, this.core)
      this.core.snapshotPainter.setRenderSize(
        this.core.size.renderWidth,
        this.core.size.renderHeight
      )
    } else {
      this.core.snapshotPainter.setCtx(ctx)
    }
  }

  initializePlayBackTimeline(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('no canvas ctx!')
    if (this.core.timeline) {
      this.core.timeline.disable()
      const tmp = new TimelineCore(this.core, ctx)
      tmp.view = this.core.timeline.view
      tmp.onSeek = this.core.timeline.onSeek
      this.core.timeline = tmp
      this.core.timeline.setCanvasRenderSize(this.core.size.wrapperWidth)
      this.core.helpers.setArchiveMap()
      this.core.helpers.setBookmark()
    } else {
      this.core.timeline = new TimelineCore(this.core, ctx)
      this.core.timeline.setCanvasRenderSize(this.core.size.wrapperWidth)
      this.core.helpers.setArchiveMap()
      this.core.helpers.setBookmark()
    }
  }

  haltPlaybackTimeline() {
    if (this.core.timeline) {
      this.core.timeline.disable()
    }
  }

  initializeHeatmap(canvas1: HTMLCanvasElement, canvas2: HTMLCanvasElement) {
    const ctx1 = canvas1.getContext('2d')
    const ctx2 = canvas2.getContext('2d')
    if (ctx1 && ctx2) {
      this.core.heatMapDisplay = new HeatMapDisplay(this.core, ctx1, ctx2)
      this.core.heatMapDisplay.setRenderSize(
        this.core.size.renderWidth,
        this.core.size.renderHeight
      )
    } else throw new Error('cant generate 2d context')
  }

  haltHeatmap() {
    // this.core.heatMapDisplay = undefined
  }

  initializeHeatmapTimeline(canvas: HTMLCanvasElement) {
    const ctx = canvas.getContext('2d')
    if (ctx) {
      this.core.heatmapTimeline = new HeatmapTimeline(this.core, ctx)
      this.core.heatmapTimeline.setCanvasRenderSize(this.core.size.wrapperWidth)
      this.core.helpers.setHeatMap()
    } else throw new Error('cant generate 2d context')
  }

  haltHeatmapTimeline() {
    if (this.core.heatmapTimeline) {
      // this.core.heatmapTimeline.disable()
    }
  }

  initializePreview(canvas: HTMLCanvasElement) {
    this.core.preview = new PlayerPreview(this.core, canvas)
  }

  haltPreview() {
    this.core.preview.destroy()
  }

  async initializeCapture(params?: PlayerParams) {
    if (this.core.capture) {
      this.core.capture.close()
    }
    this.core.capture = new CaptureClient(STREAM_PROXY_ADDRESS, this.core)
    await this.core.capture.init(params?.archiveMode !== true)
  }

  initializeTracker(canvas: HTMLCanvasElement) {
    if (this.core.annotator) {
      this.core.annotator.setCanvas(canvas)
    } else {
      this.core.annotator = new Annotator(this.core, canvas)
    }
    this.core.annotator.setRenderSize(this.core.size.renderWidth, this.core.size.renderHeight)
  }

  goToInitState(config?: PlayerInitStateConfig) {
    setTimeout(() => {
      if (config?.date) {
        if (!this.core.timeline) {
          if (this.core.playback.isLive || this.core.mode.value === FunctionMode.liveMode) {
            this.core.mode.value = FunctionMode.historyMode
            this.core.capture.archive(this.core.playback.lastFrameDate)
            this.core.decoderQueue.disable()
          }
        }
        const controller = this.core.timeline || this.core.capture
        controller.seek(config.date)
        setTimeout(() => {
          this.core.capture.speed(1)
        }, 500)
      }
    }, 500)
    setTimeout(() => {
      if (config?.analytic) {
        this.core.analyticManager.enable(convertEventType(config.analytic))
      } else {
        // enabled in component level since it need to be size sensitive
        // this.core.analyticManager.enable(AnalyticEventTypes.HwMotion)
      }
    }, 500)
  }
}
