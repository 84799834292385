import { reactive } from 'vue'
import { MouseEventsHelpers } from '@/player/lib/helpers/mouse-events-helpers'
import { timelinePositionConst } from '@/player/lib/playback-timeline/timeline-consts'
import { PlayerCore } from '@/player/lib/player/player-core'

export type SeekCallback = (value: number) => unknown
export type DateChangeCallback = (value: number, windowStart: number, windowsEnd: number) => unknown

export const RENDER_CACHE_THRESHOLD = 100

export abstract class TimelineBasic {
  public ctx!: CanvasRenderingContext2D
  protected _enabled = false
  public renderWidth = 0
  public renderHeight = 0

  public lastRender = 0

  public onSeek: undefined | SeekCallback
  public onDateChange: undefined | DateChangeCallback

  protected cacheValue = ''

  public dragHelper!: MouseEventsHelpers

  public resetRenderCache() {
    this.cacheValue = '-'
  }

  public view = reactive({
    start: 0,
    now: 0,
    end: 0,
    size: 0,
    cursor: 'pointer'
  })

  protected constructor(public readonly core: PlayerCore, ctx?: CanvasRenderingContext2D) {
    if (ctx) {
      this.setCtx(ctx)
    }
    this.setNow(Date.now())
  }

  public setCtx(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx
    if (this.dragHelper) {
      this.dragHelper.cleanup()
    }
    this.dragHelper = new MouseEventsHelpers(
      this.ctx.canvas,
      this.onDrag.bind(this),
      this.onHover.bind(this),
      this.onMouseOut.bind(this),
      this.onClick.bind(this)
    )
  }

  protected abstract calculateWindowSize(): void

  protected onDrag(dx: number, dy: number, x: number, y: number, context: string): string {
    return ''
  }

  protected onHover(x: number, y: number) {}

  protected onMouseOut() {}

  protected onClick(x: number, y: number) {}

  protected setNow(value: number) {
    this.view.now = value
    this.view.start = Math.round(this.view.now - this.view.size / 2)
    this.view.end = Math.round(this.view.now + this.view.size / 2)
    if (typeof this.onDateChange === 'function') {
      this.onDateChange(value, this.view.start, this.view.end)
    }
  }

  public seek(value: number, emitSeek = true) {
    if (emitSeek && typeof this.onSeek === 'function') {
      this.onSeek(value)
    }
    this.core.analyticManager.onSoftSeek()
    this.setNow(value)
  }

  public findRelativeXForDate(date: number) {
    const diff = date - this.view.start
    return (this.renderWidth * diff) / this.view.size
  }

  public setCanvasRenderSize(w: number, h?: number) {
    if (!h) {
      if (this.core.analyticConfig.enabled) {
        h = timelinePositionConst[this.core.size.type].renderHeightExtended
      } else {
        h = timelinePositionConst[this.core.size.type].renderHeight
      }
    }
    this.renderHeight = Math.floor(h || 0)
    this.renderWidth = Math.floor(w)
    if (this.ctx) {
      this.ctx.canvas.height = this.renderHeight
      this.ctx.canvas.width = this.renderWidth
    }
    this.calculateWindowSize()
    this.setNow(this.view.now)
  }

  get timelineRenderWidth() {
    return this.renderWidth
  }

  get timelineRenderHeight() {
    return this.renderHeight
  }

  protected abstract render(): void

  public generateCacheValue(): string {
    return ''
  }

  protected needRender() {
    if (!this.ctx) return false
    const value = this.generateCacheValue()
    if (value === this.cacheValue && Date.now() - this.lastRender < RENDER_CACHE_THRESHOLD) {
      return false
    } else {
      this.cacheValue = value
      this.lastRender = Date.now()
      return true
    }
  }

  get isEnabled() {
    return this._enabled
  }

  disable() {
    this._enabled = false
  }

  enable() {
    if (!this._enabled) {
      this._enabled = true
      this.render()
    }
  }
}
