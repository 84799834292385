import type { PlayerCore } from '@/player/lib/player/player-core'

export class SnapshotPainter {
  public ctx!: CanvasRenderingContext2D
  protected active = false

  public get style() {
    return this.core.snapshotStyle
  }

  constructor(ctx: CanvasRenderingContext2D, public readonly core: PlayerCore) {
    this.setCtx(ctx)
  }

  setCtx(ctx: CanvasRenderingContext2D) {
    if (this.ctx) {
      this.removeEventHandlers()
    }
    this.ctx = ctx
    this.setEventHandlers()
  }

  protected setEventHandlers() {
    this.ctx.canvas.addEventListener('mousedown', this.start.bind(this))
    this.ctx.canvas.addEventListener('mousemove', this.line.bind(this))
    this.ctx.canvas.addEventListener('mouseup', this.end.bind(this))
    this.ctx.canvas.addEventListener('mouseleave', this.end.bind(this))
    this.ctx.canvas.addEventListener('touchstart', this.start.bind(this))
    this.ctx.canvas.addEventListener('touchmove', this.line.bind(this))
    this.ctx.canvas.addEventListener('touchend', this.end.bind(this))
  }

  protected removeEventHandlers() {
    this.ctx.canvas.removeEventListener('mousedown', this.start.bind(this))
    this.ctx.canvas.removeEventListener('mousemove', this.line.bind(this))
    this.ctx.canvas.removeEventListener('mouseup', this.end.bind(this))
    this.ctx.canvas.removeEventListener('mouseleave', this.end.bind(this))
    this.ctx.canvas.removeEventListener('touchstart', this.start.bind(this))
    this.ctx.canvas.removeEventListener('touchmove', this.line.bind(this))
    this.ctx.canvas.removeEventListener('touchend', this.end.bind(this))
  }

  get renderWidth() {
    return this.ctx.canvas.width
  }

  get renderHeight() {
    return this.ctx.canvas.height
  }

  set renderHeight(value: number) {
    this.setRenderSize(this.ctx.canvas.width, value)
  }

  set renderWidth(value: number) {
    this.setRenderSize(value, this.ctx.canvas.height)
  }

  setRenderSize(width: number, height: number) {
    const canvasImage = this.ctx.canvas.toDataURL()

    const img = new Image()
    img.onload = () => {
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)

      this.ctx.canvas.width = width
      this.ctx.canvas.height = height

      this.ctx.drawImage(img, 0, 0, width, height)
    }
    img.src = canvasImage
  }

  protected setInitialStyles(): void {
    this.ctx.lineCap = 'round'
    this.ctx.lineWidth = this.style.size
    this.ctx.strokeStyle = this.style.color.startsWith('#')
      ? this.style.color
      : '#' + this.style.color
  }

  protected drawLine(e: MouseEvent | TouchEvent, mode: 'source-over' | 'destination-out') {
    if (this.active) {
      this.setInitialStyles()
      const clientX = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX
      const clientY = e instanceof MouseEvent ? e.clientY : e.touches[0].clientY
      this.ctx.globalCompositeOperation = mode
      const rect = this.ctx.canvas.getBoundingClientRect()
      const x = clientX - rect.left
      const y = clientY - rect.top

      this.ctx.lineTo(x, y)
      this.ctx.stroke()
      this.ctx.beginPath()
      this.ctx.moveTo(x, y)
    }
  }

  protected start(e: MouseEvent | TouchEvent) {
    if (!('button' in e) || ('button' in e && e.button === 0)) {
      e.preventDefault()
      this.active = true
      if (this.style.eraser) {
        return this.erase(e)
      } else {
        return this.draw(e)
      }
    }
  }

  protected end() {
    this.active = false
    this.ctx.beginPath()
  }

  protected draw(e: MouseEvent | TouchEvent) {
    this.drawLine(e, 'source-over')
  }

  protected erase(e: MouseEvent | TouchEvent) {
    this.drawLine(e, 'destination-out')
  }

  line(e: MouseEvent | TouchEvent) {
    e.preventDefault()
    if (this.style.eraser) {
      return this.erase(e)
    } else {
      return this.draw(e)
    }
  }

  clear() {
    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
  }
}
