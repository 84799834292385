export class EventCache {
  public data: Array<[number, number]> = []
  protected records: Array<[number, number]> = []

  constructor(public readonly id: string) {}

  public add(data: Array<[number, number]>): void {
    if (data.length > 0) {
      this.mergeData(data)
      this.flattenData()
      this.makeData()
    }
  }

  protected makeData() {
    const result: Array<[number, number]> = []
    if (this.records.length > 1) {
      for (let i = 0; i < this.records.length; i++) {
        const [date, status] = this.records[i]
        if (status === 1) {
          if (i + 1 < this.records.length) {
            result.push([date, this.records[i + 1][0]])
          }
        }
      }
    }
    this.data = result
  }

  protected flattenData() {
    if (this.records.length > 1) {
      const result: Array<[number, number]> = [this.records[0]]
      let lastState = this.records[0][1]
      for (let i = 1; i < this.records.length; i++) {
        const state = this.records[i][1]
        if (state !== lastState) {
          result.push(this.records[i])
          lastState = state
        }
      }
      this.records = result
    }
  }

  protected mergeData(newData: Array<[number, number]>): void {
    this.records.push(...newData)
    this.records.sort((a, b) => a[0] - b[0])
  }
}

export class CoverageTracker {
  private coverageData: Array<[number, number]> = []

  constructor() {}

  public mark(start: number, end: number): void {
    this.coverageData.push([start, end])
    this.mergeCoverage()
  }

  private mergeCoverage(): void {
    this.coverageData.sort((a, b) => a[0] - b[0])
    const mergedCoverage: Array<[number, number]> = []
    let lastStart: number | null = null
    let lastEnd: number | null = null

    for (const [start, end] of this.coverageData) {
      if (lastEnd !== null && start <= lastEnd) {
        lastEnd = Math.max(lastEnd, end)
      } else {
        if (lastStart !== null && lastEnd !== null) {
          mergedCoverage.push([lastStart, lastEnd])
        }
        lastStart = start
        lastEnd = end
      }
    }
    if (lastStart !== null && lastEnd !== null) {
      mergedCoverage.push([lastStart, lastEnd])
    }
    this.coverageData = mergedCoverage
  }

  public checkNeed(start: number, end: number): Array<[number, number]> | string {
    const fetchRanges: Array<[number, number]> = []

    for (const [coveredStart, coveredEnd] of this.coverageData) {
      if (coveredStart <= start && coveredEnd >= end) {
        return []
      }
      if (start < coveredStart && end > coveredEnd) {
        fetchRanges.push([start, coveredStart - 1], [coveredEnd + 1, end])
      } else if (start < coveredStart && end >= coveredStart && end <= coveredEnd) {
        fetchRanges.push([start, coveredStart - 1])
      } else if (start >= coveredStart && start <= coveredEnd && end > coveredEnd) {
        fetchRanges.push([coveredEnd + 1, end])
      }
    }

    if (fetchRanges.length > 0) {
      return fetchRanges
    }
    return [[start, end]]
  }
}
