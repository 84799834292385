import { CaptureStatus, EventTypes, PeriodType, StreamQuality } from '@/player/types'
import type { PlayerCore } from '@/player/lib/player/player-core'
import Throttle from 'lodash/throttle'
import { reactive, ref } from 'vue'
import type { CaptureCallbacks, PlayerPlaybackConfig } from '@/player/interfaces'
import type { PlayerStreamQuality } from '@/player/interfaces'
import * as Comlink from 'comlink'
import { PLAYER_MED_WIDTH } from '@/player/lib/helpers/size-helper'

export class CaptureClient {
  protected disableAudio = true

  public status = ref(CaptureStatus.unknown)

  public localMode = false
  public localConfig = reactive<PlayerPlaybackConfig>({
    lastFrameDate: 0,
    speed: 1,
    timelineSize: PeriodType.hourly,
    isLive: true,
    streamIndex: 0
  })
  public localConfigStream = reactive<PlayerStreamQuality>({
    quality: StreamQuality.medium,
    isAuto: true
  })

  softSeek!: ReturnType<typeof Throttle<typeof CaptureClient.prototype.seek>>

  get config() {
    if (this.localMode) {
      return this.localConfig
    } else {
      return this.core.playback
    }
  }

  get configStream() {
    if (this.localMode) {
      return this.localConfigStream
    } else {
      return this.core.streams
    }
  }

  get worker() {
    if (this.localMode) {
      return this.core.preview.worker
    } else {
      return this.core.worker
    }
  }
  constructor(
    protected readonly baseURL: string,
    protected readonly core: PlayerCore,
    protected readonly watchTabVisibility = true
  ) {
    this.softSeek = Throttle(this.seek.bind(this), 100)
    // todo add https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    document.addEventListener('visibilitychange', this.onTabHide.bind(this), false)
  }

  async init(initLive = true) {
    await this.worker.setCaptureCallbacks(
      Comlink.proxy<CaptureCallbacks>({
        onEvent: this.onEvent.bind(this),
        getURL: this.getURL.bind(this),
        onStatusChange: this.onStatusChange.bind(this)
      })
    )
    if (initLive) {
      setTimeout(async () => {
        if (this.core.size.renderWidth < PLAYER_MED_WIDTH) {
          await this.worker.initializeCapture(
            this.core.information.streamInformation.lowResIndex || 0
          )
          this.config.streamIndex = this.core.information.streamInformation.lowResIndex || 0
          this.configStream.quality = StreamQuality.low
        } else {
          await this.worker.initializeCapture(
            this.core.information.streamInformation.highResIndex || 0
          )
          this.config.streamIndex = this.core.information.streamInformation.highResIndex || 0
          this.configStream.quality = StreamQuality.highDefinition
        }
      })
    } else {
      await this.worker.initializeCaptureArchive(Date.now())
    }
  }

  async archive(value: number) {
    this.config.isLive = false
    this.config.speed = 1
    await this.worker.archive(value)
  }

  async live(streamIndex = 0) {
    if (!this.config.isLive || this.config.streamIndex !== streamIndex) {
      this.config.isLive = true
      this.config.speed = 1
      this.config.streamIndex = streamIndex
      await this.worker.live(streamIndex)
    }
  }

  ptz(pan: number, tilt: number, zoom: number) {
    return this.worker.ptz(pan, tilt, zoom)
  }

  async seek(value: number) {
    await this.worker.seek(value)
    this.config.speed = 0
  }

  async speed(value: number) {
    if (this.config.speed !== value) {
      await this.worker.speed(value)
      this.config.speed = value
    }
  }

  close() {
    // todo
    document.removeEventListener('visibilitychange', this.onTabHide.bind(this))
  }

  protected onTabHide() {
    if (this.watchTabVisibility) {
      if (this.status.value !== CaptureStatus.closed && document.hidden) {
        // todo stop
      } else {
        // todo hide
      }
    }
  }

  protected onEvent(ev: Set<EventTypes>) {
    if (!this.localMode) {
      this.core.watchers.onCaptureEvents(ev)
    }
  }

  protected getURL(): Promise<string> {
    return this.core.apis.streamToken().then((token) => `${this.baseURL}?token=${token}`)
  }

  protected onStatusChange(value: CaptureStatus) {
    this.status.value = value
  }
}
