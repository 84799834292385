import { defineStore } from 'pinia'
import { useServices } from '@/lib/services'
import type { Notification } from '@/lib/api'
import { groupBy, orderBy } from 'lodash'
import { NormalizeData } from '@/utils/normalize'
import { setItems, updatedItem } from '@/stores/StoreUtils'

interface NotificationState {
  notifications: Notification[]
  limit: number
  offset: number
  offsetUser: number
  isLastPage: boolean
  isLastPageInUser: boolean
}

export const useNotificationStore = defineStore('notificationStore', {
  state: (): NotificationState => ({
    notifications: [],
    limit: 10,
    offset: 0,
    offsetUser: 0,
    isLastPage: false,
    isLastPageInUser: false
  }),

  getters: {
    notificationData(): any {
      return groupBy(
        orderBy(this.notifications, 'createdAt', 'desc').map((item) => ({
          ...item,
          createdAtNormalize: String(NormalizeData.standardDate(String(item.createdAt), 'en'))
        })),
        'createdAtNormalize'
      )
    },
    haveUnreadNotification(): boolean {
      return !!this.notifications.find((item) => !item.readAt)
    }
  },

  actions: {
    async loadNextPage() {
      this.offset += this.limit
      await this.loadNotification()
    },
    async loadNotification() {
      const data = await useServices().notification.list(String(this.limit), String(this.offset))
      if (data.length === 0) this.isLastPage = true
      this.notifications = setItems(this.notifications, data) as Notification[]
    },
    async markAsSeen(id: string) {
      const data = await useServices().notification.markAsRead(id)
      updatedItem(this.notifications, data)
    },
    async loadMoreUserNotification() {
      this.offsetUser += this.limit
      await this.getBeforeSwitchNotification()
    },
    async getBeforeSwitchNotification() {
      const data = await useServices().notification.beforeSwitch(
        String(this.limit),
        String(this.offsetUser)
      )
      if (data.length === 0) this.isLastPageInUser = true
      this.notifications = setItems(this.notifications, data) as Notification[]
    }
  }
})
