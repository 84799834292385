import { AbstractHelper } from './abstract-helper'
import { useApplicationStore } from '@/stores/useApplicationStore'
import { ApiClient, ChallengeService, WorkspaceService, WorkspaceUserService } from '@/lib/api'
import { useTabStore } from '@/stores/tab/useTabStore'
import { messageType } from '@/components/general/messages/interface'
import { ConvertError } from '@/utils/helpers'
import type { Router } from 'vue-router'
import { useServices } from '@/lib/services'
import { usePaymentStore } from '@/stores/payment/usePaymentStore'
import { useAuthenticationStore } from '@/stores/authentication/useAuthenticationStore'
import { useWorkspaceStore } from '@/stores/WorkspaceSetingStore/UseWorkspaceStore'
import {
  defaultOperationHours,
  UpdateMainSchedule
} from '@/lib/api/helpers/alert/update-main-schedule'

export class WorkspaceHelper extends AbstractHelper {
  protected workspaceService: WorkspaceService
  protected challengeService: ChallengeService
  protected userService: WorkspaceUserService

  constructor(apiClient: ApiClient) {
    super(apiClient)
    this.workspaceService = apiClient.workspaceManager.workspace
    this.challengeService = apiClient.authenticationManager.challenge
    this.userService = apiClient.workspaceManager.user
  }

  public async createWorkSpaceFunc(router: Router, toast) {
    try {
      useApplicationStore().startLoading('createWorkspace')
      await useAuthenticationStore().checkUpdatingAction()
      useAuthenticationStore().resetAllStore(false)
      await useWorkspaceStore().createNewWorkspace()
      await useAuthenticationStore().reopenSessionSocket()
      await useApplicationStore().loadMainData()
      await Promise.all([
        usePaymentStore().createTrialSubscription(),
        UpdateMainSchedule(defaultOperationHours)
      ])
      await router.push({ name: 'application' })
    } catch (e) {
      toast.add({
        severity: messageType.error,
        summary: 'Service Error',
        detail: ConvertError(e),
        life: 3000
      })
    } finally {
      useApplicationStore().stopLoading('createWorkspace')
    }
  }

  public integerToRoman(num) {
    if (typeof num !== 'number') return false

    const key = [
      '',
      'C',
      'CC',
      'CCC',
      'CD',
      'D',
      'DC',
      'DCC',
      'DCCC',
      'CM',
      '',
      'X',
      'XX',
      'XXX',
      'XL',
      'L',
      'LX',
      'LXX',
      'LXXX',
      'XC',
      '',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX'
    ]
    const digits = String(+num).split('')
    let roman_num = ''
    let i = 3
    while (i--) {
      // @ts-ignore
      roman_num = (key[+digits.pop() + i * 10] || '') + roman_num
    }
    return Array(+digits.join('') + 1).join('M') + roman_num
  }

  public async getWorkspaceAvatar(workspaceId) {
    const image = await useServices().workspaceManager.workspace.avatarGet(String(workspaceId))
    if (image.data && image.data.size > 0) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(image.data)
      })
    }
    return null // or some default value if no image is found
  }

  public getRandomIntInclusive(min: number, max: number): number {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
}
