import auth from '@/middleware/auth'
import { Actions } from '@/modules/Permissions'

export const routesObject = [
  { route: 'clips', key: 'tabMenu.clips', arial: 'create-clip-tab', name: 'My Clips' },
  { route: 'clips', key: 'tabMenu.download', arial: 'create-download-tab', name: 'Download' },
  { route: 'clips', key: 'tabMenu.snapshots', arial: 'create-snapshot-tab', name: 'Snapshots' },
  { route: 'clips', key: 'tabMenu.exports', arial: 'create-export-tab', name: 'Exports' },
  // {
  //   route: 'AiHeatMap',
  //   key: 'tabMenu.AiHeatMap',
  //   arial: 'create-heatmap-tab',
  //   name: 'AI Heatmaps'
  // },
  // {
  //   route: 'AiHeatMap',
  //   key: 'tabMenu.activityHotspots',
  //   arial: 'create-activityHotspots-tab',
  //   name: 'Activity Hotspots'
  // },
  {
    route: 'monitoring',
    key: 'tabMenu.monitoring',
    arial: 'create-monitoring-tab',
    name: 'Monitoring'
  },

  {
    route: 'monitoring',
    key: 'tabMenu.liveStreaming',
    arial: 'create-liveStreaming-tab',
    name: 'Live Streaming'
  },

  {
    route: 'monitoring',
    key: 'tabMenu.allDevices',
    arial: 'create-allDevices-tab',
    name: 'All Devices'
  },
  {
    route: 'monitoring',
    key: 'tabMenu.listView',
    arial: 'create-listView-tab',
    name: 'List View'
  },

  {
    route: 'permissionSettings',
    key: 'tabMenu.people',
    arial: 'create-people-tab',
    name: 'People'
  },
  {
    route: 'permissionSettings',
    key: 'tabMenu.permissions',
    arial: 'create-people-tab',
    name: 'Permissions'
  },

  {
    route: 'billingSettings',
    key: 'tabMenu.invoice',
    arial: 'create-invoice-tab',
    name: 'Invoices'
  },
  {
    route: 'billingSettings',
    key: 'tabMenu.billing',
    arial: 'create-billing-tab',
    name: 'Billing'
  },

  {
    route: 'billingSettings',
    key: 'tabMenu.paymentMethod',
    arial: 'create-paymentMethod-tab',
    name: 'Payment Method'
  },
  {
    route: 'billingSettings',
    key: 'tabMenu.upcomingPayments',
    arial: 'create-upcomingPayments-tab',
    name: 'Upcoming Payments'
  },

  {
    route: 'cameraConnect',
    key: 'tabMenu.cameraConnect',
    arial: 'create-cameraConnect-tab',
    name: 'Connect Cameras'
  },
  { route: 'billingSettings', key: 'tabMenu.myPlan', arial: 'create-my-plan', name: 'My Plan' },
  {
    route: 'billingSettings',
    key: 'tabMenu.subscription',
    arial: 'create-subscription',
    name: 'Subscription'
  },
  {
    route: 'billingSettings',
    key: 'tabMenu.upgradeDowngrade',
    arial: 'create-upgradeDowngrade',
    name: 'Upgrade/Downgrade'
  },

  {
    route: 'securitySettings',
    key: 'tabMenu.securitySettings',
    arial: 'create-security-tab',
    name: 'Security'
  },
  {
    route: 'securitySettings',
    key: 'tabMenu.loggedDevices',
    arial: 'create-loggedDevices-tab',
    name: 'Logged in Devices'
  },
  {
    route: 'securitySettings',
    key: 'tabMenu.multiFactorAuthentication',
    arial: 'create-multiFactorAuthentication-tab',
    name: 'Multi-factor Authentication'
  },

  {
    key: 'tabMenu.resetPassword',
    route: 'reset-password',
    arial: 'create-resetPassword-tab',
    name: 'Reset Password'
  },
  {
    key: 'tabMenu.newPassword',
    route: 'reset-password',
    arial: 'create-newPassword-tab',
    name: 'New Password'
  },

  {
    key: 'tabMenu.passwordRecovery',
    route: 'recovery-password',
    arial: 'create-recoveryPassword-tab',
    name: 'Password Recovery'
  },
  {
    key: 'tabMenu.profileSettings',
    route: 'profileSettings',
    arial: 'create-profileSetting-tab',
    name: 'My Profile'
  },
  {
    key: 'tabMenu.emailVerification',
    route: 'profileSettings',
    arial: 'create-emailVerification-tab',
    name: 'Email Verification'
  },
  {
    key: 'tabMenu.PhoneVerification',
    route: 'profileSettings',
    arial: 'create-PhoneVerification-tab',
    name: 'Phone No. Verification'
  },
  {
    key: 'tabMenu.newPhoneNo',
    route: 'profileSettings',
    arial: 'create-newPhoneNo-tab',
    name: 'New Phone No.'
  },
  {
    key: 'tabMenu.newEmail',
    route: 'profileSettings',
    arial: 'create-newEmail-tab',
    name: 'New Email'
  },

  {
    key: 'tabMenu.myTimezone',
    route: 'profileSettings',
    arial: 'create-myTimezone-tab',
    name: 'My Timezone'
  },
  {
    key: 'tabMenu.themes',
    route: 'profileSettings',
    arial: 'create-themes-tab',
    name: 'Themes'
  },

  {
    key: 'tabMenu.workspaceSettings',
    route: 'workspaceSettings',
    arial: 'create-workspaceSetting-tab',
    name: 'Workspace Overview'
  },
  {
    key: 'tabMenu.tutorial',
    route: 'tutorial',
    arial: 'create-tutorial-tab',
    name: 'How-To Videos'
  },
  {
    key: 'tabMenu.shortcuts',
    route: 'shortcuts',
    arial: 'create-shortcut-tab',
    name: 'Shortcuts'
  },
  {
    key: 'tabMenu.logs',
    route: 'workspaceSettings',
    arial: 'create-workspaceSetting-tab',
    name: 'Logs'
  },
  {
    key: 'tabMenu.activityLogs',
    route: 'workspaceSettings',
    arial: 'create-workspaceSetting-tab',
    name: 'Activity Logs'
  },
  {
    key: 'tabMenu.userLogs',
    route: 'workspaceSettings',
    arial: 'create-workspaceSetting-tab',
    name: 'User Logs'
  },
  {
    key: 'tabMenu.auditTrails',
    route: 'workspaceSettings',
    arial: 'create-workspaceSetting-tab',
    name: 'Audit Trails'
  }
]

export interface routeEachInObject {
  key: String
  route: String
  name: String
}

export default [
  {
    path: '/',
    name: 'authentication',
    component: () => import('../views/authentication/Authentication.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/authentication/ResetPassword.vue')
  },
  {
    path: '/recovery-password',
    name: 'recovery-password',
    component: () => import('../views/authentication/RecoveryMultiFactor.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/errors/404.vue')
  },
  {
    path: '/login-away-error',
    name: 'loginAwayError',
    component: () => import('@/views/errors/LoginAwayError.vue')
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/views/errors/forbidden.vue')
  },
  {
    path: '/interruption',
    name: 'interruption',
    component: () => import('@/views/errors/interruption.vue')
  },
  {
    path: '/capacity',
    name: 'capacity',
    component: () => import('@/views/authentication/Capacity.vue')
  },
  {
    path: '/app',
    name: 'application',
    component: () => import('@/layouts/index.vue'),
    meta: {
      middleware: [auth]
    },
    children: [
      {
        path: 'profile',
        name: 'profile',
        redirect: { name: 'profileSettings' },
        component: () => import('../views/profile/profileSettings.vue'),
        children: [
          {
            path: 'settings',
            name: 'profileSettings',
            component: () => import('@/components/profile/profile.vue')
          },
          {
            path: 'security',
            name: 'securitySettings',
            component: () => import('@/components/security/security.vue')
          }
        ]
      },
      {
        path: 'workspace',
        name: 'workspace',
        redirect: { name: 'workspaceSettings' },
        component: () => import('../views/workspace/workspaceSettings.vue'),
        children: [
          {
            path: 'settings',
            name: 'workspaceSettings',
            component: () => import('@/components/workspace/WorkspaceContainer.vue')
          },
          {
            path: 'billing',
            name: 'billingSettings',
            meta: {
              actions: [{ action: Actions.workspaceManagePayment }]
            },
            component: () => import('@/components/billing/BillingContainer.vue')
          }
        ]
      },
      {
        path: 'permission-settings',
        name: 'permissionSettings',
        meta: {
          actions: [
            { action: Actions.workspaceViewUsers },
            { action: Actions.workspaceViewPermissions }
          ]
        },
        component: () => import('../modules/Permissions/views/index.vue')
      },
      {
        path: 'onboarding',
        name: 'onboarding',
        meta: {
          actions: [
            { action: Actions.workspaceUpdateInformation },
            { action: Actions.workspaceManagePayment },
            { action: Actions.cameraCreate },
            { action: Actions.bridgeActivate },
            { action: Actions.cameraGroupCreate },
            { action: Actions.cameraGroupRead }
          ]
        },
        component: () => import('../views/onboarding-bridge/index.vue')
      },
      {
        name: 'help',
        path: 'help',
        children: [
          {
            name: 'tutorial',
            path: 'tutorial',
            component: () => import('../views/onboarding-bridge/Tutorial.vue')
          },
          {
            path: 'shortcuts',
            name: 'shortcuts',
            component: () => import('../modules/Shortcuts/views/index.vue')
          }
        ]
      },
      {
        path: 'camera-connect',
        name: 'cameraConnect',
        meta: {
          actions: [
            { action: Actions.cameraCreate },
            { action: Actions.bridgeActivate },
            { action: Actions.cameraGroupCreate },
            { action: Actions.cameraGroupRead }
          ]
        },
        component: () => import('../views/onboarding-bridge/index.vue')
      },
      {
        path: 'stream',
        name: 'monitoring',
        meta: {
          // actions: [{ action: Actions.cameraView }] // this is not relevant and actually wrong since the backend filter the list of cameras
        },
        component: () => import('../views/stream/index.vue')
      },
      {
        path: 'playback',
        name: 'playback',
        meta: {
          actions: [{ action: Actions.cameraReadBookmark }, { action: Actions.cameraStreamArchive }]
        },
        component: () => import('../views/stream/sync-playback.vue')
      },
      {
        path: 'ai-search/:id/:chatId',
        name: 'aiSearch',
        meta: {
          actions: [{ action: Actions.aiTrackFind }, { action: Actions.aiTrackStatisticsFind }]
        },
        component: () => import('../views/ai-search/index.vue')
      },
      {
        path: 'ai-heatmap',
        name: 'AiHeatMap',
        meta: {
          actions: [{ action: Actions.aiHeatmapFind }]
        },
        component: () => import('@/views/heatMap/heatMap2.vue')
      },
      {
        path: 'clips',
        name: 'clips',
        meta: {
          // actions: [{ action: Actions.cameraViewExport }, { action: Actions.cameraViewSnapshot }] // this is not relevant and actually wrong since the backend filter the list of cameras
        },
        component: () => import('../views/clips/index.vue')
      },
      {
        path: 'camera',
        name: 'camera',
        component: () => import('../views/camera-detail/index.vue'),
        redirect: { name: 'cameraDetail' },
        children: [
          {
            path: 'detail/:id',
            name: 'cameraDetail',
            component: () => import('@/views/camera-detail/CameraStream.vue')
          }
        ]
      },
      {
        path: 'camera-permission/:id',
        name: 'cameraPermission',
        component: () => import('../views/camera-detail/index.vue')
      }
    ]
  },
  {
    path: '/select-workspace',
    name: 'SelectWorkspace',
    meta: {
      middleware: [auth]
    },
    component: () => import('../views/workspace/SelectWorkspace.vue')
  }
]
