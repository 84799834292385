import type { VirtualPermissions } from '..'
import { ResourceTypes } from '@/lib/api/services/permission/resource-type'

export enum Actions {
  workspaceReadPublicLayout = 0,
  workspaceUpdatePublicLayout,
  mapRead,
  mapUpdate,
  mapCreate,
  workspaceManageUsers = 6,
  workspaceManagePermissions,
  workspaceManagePayment,
  cameraView, // name description - tag metadata
  cameraCreate,
  cameraViewSettings, // connection (IP/ password/ ...)
  cameraUpdateSettings,
  cameraStreamLive,
  cameraStreamArchive,
  cameraPTZAccess,
  cameraDownloadExport,
  cameraCreateExport,
  cameraCreateBookmark,
  cameraViewBookmark,
  cameraGroupRead,
  cameraGroupCreate,
  cameraGroupUpdate,
  cameraGroupRemove,
  cameraRemove,
  cameraUpdate, // name description - tag metadata
  cameraUpdateBookmark,
  cameraReadBookmark,
  cameraRemoveBookmark,
  workspaceUpdateInformation,
  bridgeActivate,
  bridgeFind,
  bridgeUpdate,
  bridgeRemove,
  bridgeDiscoverCamera,
  bridgeForget,
  bridgeHeartbeat,
  bridgeReset,
  bridgeGetHandlers,
  bridgeSync,
  bridgeViewDiscoveryResponse,
  aiTrackFind,
  aiTrackStatisticsFind,
  cameraCreateSnapshot,
  cameraViewSnapshot,
  cameraDownloadSnapshot,
  cameraRemoveSnapshot,
  cameraViewExport,
  cameraRemoveExport,
  // cameraStreamThumbnail,
  aiHeatmapFind = 50,
  workspaceViewPermissions,
  workspaceViewUsers,
  analyticsFind,
  cameraHealthView,
  analyticsRead,
  analyticsWrite,
  privacyMaskUpdate,
  // Gererals
  readMetadata = 10000,
  updateMetadata,
  createTag,
  updateTag,
  createTagRelation,
  updateTagRelation,
  assignTag,
  dropTag,
  readActionLog
}

export enum Access {
  FullControl = 1,
  ViewOnly,
  Hidden
}

export interface AuthoritiesFinderOptions {
  action: Actions | string
  resourceId?: string
}

export interface PermisisonsFinderOptions {
  permission: VirtualPermissions | string
  access?: Access[] | string[]
}

export interface MatrixAuthorities {
  resourceId: string
  type: ResourceTypes
  key: Actions
}
