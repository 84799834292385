export default [
  {
    path: '/sandbox',
    name: 'sandbox.root',
    component: () => import('../views/sandbox/LinksRoutes.vue'),
    children: [
      {
        path: 'BillingAddressSandbox',
        name: 'sandbox.BillingAddressSandbox',
        component: () => import('../views/sandbox/BillingAddressSandbox.vue')
      },
      {
        path: 'stripe',
        name: 'sandbox.stripe',
        component: () => import('../views/sandbox/StripeSandbox.vue')
      },
      {
        path: 'input',
        name: 'sandbox.inputs',
        component: () => import('../views/sandbox/InputUIKit.vue')
      },
      {
        path: 'buttons',
        name: 'sandbox.buttons',
        component: () => import('../views/sandbox/ButtonsSandbox.vue')
      },
      {
        path: 'tag-selection',
        name: 'sandbox.tag-selection',
        component: () => import('../views/sandbox/TagSelectionSandBox.vue')
      },
      {
        path: 'phone-input',
        name: 'sandbox.phone-input',
        component: () => import('../views/sandbox/PhoneInputSandbox.vue')
      },
      {
        path: 'SignUp-footer',
        name: 'sandbox.signup-footer',
        component: () => import('../views/sandbox/SignUpFooter.vue')
      },
      {
        path: 'logo',
        name: 'sandbox.logo',
        component: () => import('../views/sandbox/LogoSandbox.vue')
      },
      {
        path: 'data-deletion',
        name: 'sandbox.data-deletion',
        component: () => import('../views/sandbox/DataDeletionSandbox.vue')
      },
      {
        path: 'social-login-button',
        name: 'sandbox.social-login-button',
        component: () => import('../views/sandbox/SocialLoginButtonSandbox.vue')
      },
      {
        path: 'count-down-timer',
        name: 'sandbox.count-down-timer',
        component: () => import('../views/sandbox/CountdownTimerSandbox.vue')
      },
      {
        path: 'otp-input',
        name: 'sandbox.otp',
        component: () => import('../views/sandbox/OtpInputSandbox.vue')
      },
      {
        path: 'slider',
        name: 'sandbox.slider',
        component: () => import('../views/sandbox/CarouselSandbox.vue')
      },
      {
        path: 'reset-password',
        name: 'sandbox.reset-password',
        component: () => import('../views/sandbox/ResetPasswordSandbox.vue')
      },
      {
        path: 'two-factor',
        name: 'sandbox.two-factor',
        component: () => import('../views/sandbox/TwoFactorAuthenticationSandBox.vue')
      },
      {
        path: 'security-login-login-rules',
        name: 'sandbox.security-login-login-rules',
        component: () => import('../views/sandbox/LoginRulesSandbox.vue')
      },
      {
        path: 'permanent-delete',
        name: 'sandbox.permanent-delete',
        component: () => import('../views/sandbox/PermanentDeleteSandBox.vue')
      },
      {
        path: 'capacity',
        name: 'sandbox.capacity',
        component: () => import('../views/sandbox/CapacitySandbox.vue')
      },
      {
        path: '2fa.recovery',
        name: 'sandbox.2fa.recovery',
        component: () => import('../views/sandbox/2FaRecoverySandbox.vue')
      },
      {
        path: 'logo',
        name: 'sandbox.logo',
        component: () => import('../views/sandbox/LogoSandbox.vue')
      },
      {
        path: 'profile-image',
        name: 'sandbox.profile-image',
        component: () => import('../views/sandbox/ProfileImage.vue')
      },
      {
        path: 'notFound',
        name: 'sandbox.notFound',
        component: () => import('../views/sandbox/NotFoundSandbox.vue')
      },
      {
        path: 'interrupt',
        name: 'sandbox.interrupt',
        component: () => import('../views/sandbox/InterruptSandbox.vue')
      },
      {
        path: 'noAccess',
        name: 'sandbox.noAccess',
        component: () => import('../views/sandbox/NoAccessSandbox.vue')
      },
      {
        path: 'twitter',
        name: 'sandbox.twitter',
        component: () => import('../views/sandbox/TwitterSandbox.vue')
      },
      {
        path: 'profile-name-card',
        name: 'sandbox.profile-name-card',
        component: () => import('../views/sandbox/ProfileNameCardSandbox.vue')
      },
      {
        path: 'message-form',
        name: 'sandbox.message-form',
        component: () => import('../views/sandbox/FormMessageCardSandbox.vue')
      },
      {
        path: 'profile-email-card',
        name: 'sandbox.profile-email-card',
        component: () => import('../views/sandbox/ProfileEmailCardSandbox.vue')
      },
      {
        path: 'new-phone-verify',
        name: 'sandbox.new-phone-verify',
        component: () => import('../views/sandbox/NewPhoneVerificationSandbox.vue')
      },
      {
        path: 'theme-change',
        name: 'sandbox.them-change',
        component: () => import('../views/sandbox/ThemeChange.vue')
      },
      {
        path: 'profile-trail-box',
        name: 'sandbox.profile-trail-box',
        component: () => import('../views/sandbox/TrailBoxCardSandBox.vue')
      },
      {
        path: 'security-session-box',
        name: 'sandbox.security-session-box',
        component: () => import('../views/sandbox/SessionsBoxCardSandBox.vue')
      },
      {
        path: 'top-menu',
        name: 'sandbox.top-menu',
        component: () => import('../views/sandbox/TopMenuSandbox.vue')
      },
      {
        path: 'profile',
        name: 'sandbox.profile',
        component: () => import('../components/profile/profile.vue')
      },
      {
        path: 'workspace-people',
        name: 'sandbox.workspace-people',
        component: () => import('../views/sandbox/WorkspacePeopleSandbox.vue')
      },
      {
        path: 'password-field',
        name: 'sandbox.password-field',
        component: () => import('../views/sandbox/PasswordFieldSandBox.vue')
      },
      {
        path: 'work-space-space-avatar',
        name: 'sandbox.work-space-space-avatar',
        component: () => import('../views/sandbox/WorkSpaceSpaceAvatarSandBox.vue')
      },
      {
        path: 'detail-setting',
        name: 'sandbox.detail-setting',
        component: () => import('../views/sandbox/WorkspaceDetailSettingSandbox.vue')
      },
      {
        path: 'select-workspace',
        name: 'sandbox.select-workspace',
        component: () => import('../views/sandbox/SelectWorkspaceSandbox.vue')
      },
      // {
      //   path: 'ws-transfer-ownerShip',
      //   name: 'sandbox.ws-transfer-ownerShip',
      //   component: () => import('../views/sandbox/WSTransferOwnerShipSandBox.vue')
      // },
      {
        path: 'workspace-devices',
        name: 'sandbox.workspace-devices',
        component: () => import('../views/sandbox/WorkspaceDevices.vue')
      },
      {
        path: 'upcoming-workspace',
        name: 'sandbox.upcoming-workspace',
        component: () => import('../views/sandbox/WorkspaceUpcomingSandbox.vue')
      },
      // {
      //   path: 'permissions-roles',
      //   name: 'sandbox.permissions-roles',
      //   component: () => import('../views/sandbox/PermissionsRolesSandBox.vue')
      // },
      // {
      //   path: 'permissions-table',
      //   name: 'sandbox.permissions-table',
      //   component: () => import('../views/sandbox/PermissionsTableSandBox.vue')
      // },
      // {
      //   path: 'people-profile',
      //   name: 'sandbox.people-profile',
      //   component: () => import('../views/sandbox/PeopleProfileSidebarSandbox.vue')
      // },
      {
        path: 'static-ip',
        name: 'sandbox.static-ip',
        component: () => import('../views/sandbox/CameraConnectStaticIpSandbox.vue')
      },
      {
        path: 'camera-connect-verifyIp',
        name: 'sandbox.verifyIp',
        component: () => import('../views/sandbox/CameraConnectVerifyIpSandbox.vue')
      },
      {
        path: 'camera-connect-configureIp',
        name: 'sandbox.configureIp',
        component: () => import('../views/sandbox/CameraConnectConfigureIpSandbox.vue')
      },
      {
        path: 'camera-marketing',
        name: 'sandbox.camera-marketing',
        component: () => import('../views/sandbox/MarketingCameraConnectSandbox.vue')
      },
      {
        path: 'onboarding-alert',
        name: 'sandbox.onboarding-alert',
        component: () => import('../views/sandbox/OnboardingAlertSandbox.vue')
      }
    ]
  }
]
