import { PeriodType } from '@/player/types'
import type { DrawConfigTimeline, DrawConfigTimelinePositions } from '@/player/interfaces'

const defaultConfig: DrawConfigTimeline = {
  centerLine: {
    line: {
      height: 35,
      width: 1,
      borderRadius: 4,
      color: {
        fill: '#FFFFFF'
      },
      shadow: {
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        color: 'rgba(0, 0, 0, 0)'
      }
    },
    circle: {
      radius: 0,
      color: {
        fill: '#FFFFFF'
      },
      shadow: {
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        color: 'rgba(0, 0, 0, 0)'
      }
    }
  },
  horizontalLine: {
    height: 1,
    width: 1024,
    borderRadius: 4,
    color: {
      fill: '#BA1A1A'
    }
  },
  horizontalLinePlaceHolder: {
    height: 1,
    width: 1024,
    borderRadius: 0,
    color: {
      fill: '#000000'
    }
  },
  selector: {
    width: 150,
    height: 23,
    borderRadius: 2,
    color: {
      fill: '#FFFFFF'
    }
  },
  selectorHandler: {
    width: 2,
    height: 40,
    borderRadius: 4,
    color: {
      fill: '#FFFFFF'
    }
  },
  bookmark: {
    width: 150,
    height: 23,
    borderRadius: 2,
    color: {
      fill: '#FFFFFF'
    }
  },
  periodSize: 52,
  periodLine: {
    height: 8,
    width: 2,
    borderRadius: 4,
    color: {
      fill: '#FFFFFF'
    },
    shadow: {
      offsetX: 0,
      offsetY: 0,
      blur: 14,
      color: 'rgba(0, 0, 0, 0.25)'
    }
  },
  simiPeriodCount: 5,
  simiPeriodLine: {
    height: 4,
    width: 1,
    borderRadius: 4,
    color: {
      fill: '#FFFFFF'
    },
    shadow: {
      offsetX: 0,
      offsetY: 0,
      blur: 14,
      color: 'rgba(0, 0, 0, 0.25)'
    }
  },
  font: {
    family: 'Montserrat',
    size: 6,
    weight: 600,
    lineHeight: 9,
    color: '#FFFFFF'
  },
  dateLabel: {
    color: '#70D1F2',
    borderRadius: 2,
    paddingX: 2,
    paddingY: 1,
    font: {
      family: 'Montserrat',
      size: 4,
      weight: 500,
      lineHeight: 5,
      color: '#000000'
    }
  }
}

const defaultConfigLarge = {
  centerLine: {
    line: {
      height: 55,
      width: 2,
      borderRadius: 4,
      color: {
        fill: '#FFFFFF'
      },
      shadow: {
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        color: 'rgba(0, 0, 0, 0)'
      }
    },
    circle: {
      radius: 0,
      color: {
        fill: '#FFFFFF'
      },
      shadow: {
        offsetX: 0,
        offsetY: 0,
        blur: 0,
        color: 'rgba(0, 0, 0, 0)'
      }
    }
  },
  horizontalLine: {
    height: 2,
    width: 1024,
    borderRadius: 4,
    color: {
      fill: '#BA1A1A'
    }
  },
  horizontalLinePlaceHolder: {
    height: 2,
    width: 1024,
    borderRadius: 0,
    color: {
      fill: '#000000'
    }
  },
  selector: {
    width: 150,
    height: 45,
    borderRadius: 2,
    color: {
      fill: '#FFFFFF'
    }
  },
  selectorHandler: {
    width: 4,
    height: 60,
    borderRadius: 4,
    color: {
      fill: '#FFFFFF'
    }
  },
  bookmark: {
    width: 150,
    height: 45,
    borderRadius: 2,
    color: {
      fill: '#FFFFFF'
    }
  },
  periodSize: 52,
  periodLine: {
    height: 8,
    width: 2,
    borderRadius: 4,
    color: {
      fill: '#FFFFFF'
    },
    shadow: {
      offsetX: 0,
      offsetY: 0,
      blur: 14,
      color: 'rgba(0, 0, 0, 0.25)'
    }
  },
  simiPeriodCount: 5,
  simiPeriodLine: {
    height: 4,
    width: 1,
    borderRadius: 4,
    color: {
      fill: '#FFFFFF'
    },
    shadow: {
      offsetX: 0,
      offsetY: 0,
      blur: 14,
      color: 'rgba(0, 0, 0, 0.25)'
    }
  },
  font: {
    family: 'Montserrat',
    size: 12,
    weight: 500,
    lineHeight: 16,
    color: '#FFFFFF'
  },
  dateLabel: {
    color: '#70D1F2',
    borderRadius: 4,
    paddingX: 2,
    paddingY: 1,
    font: {
      family: 'Montserrat',
      size: 6,
      weight: 500,
      lineHeight: 9,
      color: '#000000'
    }
  }
}

export const timelineRenderConst: Record<
  'small' | 'medium' | 'large',
  Record<PeriodType, DrawConfigTimeline>
> = {
  small: {
    [PeriodType.singleMinute]: defaultConfig,
    [PeriodType.minimal]: defaultConfig,
    [PeriodType.quarter]: {
      ...defaultConfig,
      simiPeriodCount: 3
    },
    [PeriodType.hourly]: {
      ...defaultConfig,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.daily]: {
      ...defaultConfig,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.monthly]: {
      ...defaultConfig,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.threeMonth]: {
      ...defaultConfig,
      simiPeriodCount: 3
    }
  },
  medium: {
    [PeriodType.singleMinute]: defaultConfig,
    [PeriodType.minimal]: defaultConfig,
    [PeriodType.quarter]: {
      ...defaultConfig,
      simiPeriodCount: 3
    },
    [PeriodType.hourly]: {
      ...defaultConfig,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.daily]: {
      ...defaultConfig,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.monthly]: {
      ...defaultConfig,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.threeMonth]: {
      ...defaultConfig,
      simiPeriodCount: 3
    }
  },
  large: {
    [PeriodType.singleMinute]: defaultConfigLarge,
    [PeriodType.minimal]: defaultConfigLarge,
    [PeriodType.quarter]: {
      ...defaultConfigLarge,
      simiPeriodCount: 3
    },
    [PeriodType.hourly]: {
      ...defaultConfigLarge,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.daily]: {
      ...defaultConfigLarge,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.monthly]: {
      ...defaultConfigLarge,
      periodSize: 69,
      simiPeriodCount: 4
    },
    [PeriodType.threeMonth]: {
      ...defaultConfigLarge,
      simiPeriodCount: 3
    }
  }
}
export const timelinePositionConst: Record<
  'small' | 'medium' | 'large',
  DrawConfigTimelinePositions
> = {
  small: {
    renderHeight: 40,
    renderHeightExtended: 40,
    horizontalLinePlaceHolder: 35,
    horizontalLine: 35,
    centerLine: 35,
    font: 24,
    label: 35,
    periodLine: 35,
    simiPeriodLine: 35,
    dateLabel: 35,
    bookmark: 40,
    selector: 40,
    selectorHandler: 45
  },
  medium: {
    renderHeight: 45,
    renderHeightExtended: 45,
    horizontalLinePlaceHolder: 35,
    horizontalLine: 35,
    centerLine: 35,
    font: 24,
    label: 35,
    periodLine: 35,
    simiPeriodLine: 35,
    dateLabel: 35,
    bookmark: 40,
    selector: 40,
    selectorHandler: 45
  },
  large: {
    renderHeight: 70,
    renderHeightExtended: 94,
    horizontalLinePlaceHolder: 55,
    horizontalLine: 55,
    centerLine: 55,
    font: 45,
    label: 55,
    periodLine: 55,
    simiPeriodLine: 55,
    dateLabel: 35,
    bookmark: 60,
    selector: 60,
    selectorHandler: 65
  }
}
