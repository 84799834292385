import { CaptureClient } from '@/player/lib/capture/capture-client'
import { PlayerCore } from '@/player/lib/player/player-core'
import { DecoderImageDisplay } from '@/player/lib/decoder/decoder-image-display'
import { STREAM_PROXY_ADDRESS } from '@/player/lib/player/player-helpers'
import { watch } from 'vue'
import * as Comlink from 'comlink'
import type { PlayerWorkerCore, PlayerWorkerCoreConstructor } from '@/player/interfaces'
import { worker_url } from '@/player/types'

export class PlayerPreview {
  public workerHandler!: Worker
  public worker!: Comlink.Remote<PlayerWorkerCore>
  public capture!: CaptureClient
  public display!: DecoderImageDisplay

  public width = 200
  public height = (this.width / 4) * 3

  constructor(public readonly core: PlayerCore, public readonly canvas: HTMLCanvasElement) {
    this.initialize()
  }

  protected async initializeCapture() {
    this.capture = new CaptureClient(STREAM_PROXY_ADDRESS, this.core, false)
    this.capture.localMode = true
    await this.capture.init(false)
  }

  protected async initializeDisplay() {
    this.display = new DecoderImageDisplay(this.core)
    this.display.localMode = true
    await this.display.init()
    await this.display.setCanvas(this.canvas)
    await this.display.setRenderSize(this.width, this.height)
    this.display.localSizeCallback = (w, h) => {
      const height = (h * this.width) / w
      if (height !== this.height) {
        this.display.setRenderSize(this.width, height)
        this.height = height
      }
    }
    await this.setMask()
  }

  protected async initialize() {
    this.workerHandler = new Worker(worker_url)
    const proxy = Comlink.wrap<PlayerWorkerCoreConstructor>(this.workerHandler)
    this.worker = await new proxy()
    await this.initializeDisplay()
    await this.initializeCapture()
    watch(() => this.core.previewConfig.date, this.update.bind(this))
    watch(
      [() => this.core.maskConfig.enabled, () => this.core.information.maskData],
      this.setMask.bind(this)
    )
  }

  protected update() {
    if (this.core.previewConfig.enabled) {
      this.seek(this.core.previewConfig.date)
    }
  }

  protected async setMask() {
    if (this.core.maskConfig.enabled && this.core.information.maskData) {
      const value = JSON.parse(JSON.stringify(this.core.information.maskData))
      await this.worker.setMaskData(value)
    } else {
      await this.worker.clearMask()
    }
  }

  seek(date: number) {
    this.capture.softSeek(date)
  }

  destroy() {
    if (this.capture) {
      this.capture.close()
    }
    if (this.display) {
      this.display.destroy()
    }
    if (this.workerHandler) {
      this.workerHandler.terminate()
    }
  }
}
