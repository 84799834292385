import {
  type Camera as CameraObject,
  type CameraReturnType,
  CameraService,
  ResourceTypes
} from '@/lib/api'
import { useServices } from '@/lib/services'
import type { CameraTemp } from '@/modules/camera-new/interface'
import { CameraConnectionInformation } from '@/modules/camera-new/libs/connection-information/CameraConnectionInformation'
import { CameraBaseInfo } from '@/modules/camera-new/libs/camera-base-info/CameraBaseInfo'
import { CameraRecord } from '@/modules/camera-new/libs/camera-record/CameraRecord'
import { CameraAvatar } from '@/modules/camera-new/libs/avatar/CameraAvatar'
import { CameraStatus } from '@/modules/camera-new/libs/status/CameraStatus'
import { CameraThumbnail } from '@/modules/camera-new/libs/thumbnail/CameraThumbnail'
import { TagManager } from '@/modules/tag/lib/TagManager'
import { MetadataManager } from '@/modules/metadata/lib/MetadataManager'
import { CameraHardware } from '@/modules/camera-new/libs/hardware-information/CameraHardware'
import { CameraStream } from '@/modules/camera-new/libs/stream-information/CameraStream'
import { CameraStatusType } from '@/modules/camera-new/types'
import { CameraGroup } from '@/modules/camera-new/libs/camera-group/CameraGroup'
import { CameraBridgePivot } from '@/modules/camera-new/libs/bridge-pivot/CameraBridgePivot'
import { CameraUsers } from '@/modules/camera-new/libs/users/CameraUsers'
import { CameraLocation } from '@/modules/camera-new/libs/location/CameraLocation'

export class CameraCore {
  public readonly id: string
  protected service: CameraService = useServices().camera

  public readonly base: CameraBaseInfo
  public readonly connection: CameraConnectionInformation
  public readonly users: CameraUsers
  public readonly record: CameraRecord
  public readonly avatar: CameraAvatar
  public readonly status: CameraStatus
  public readonly thumbnail: CameraThumbnail
  public readonly tagManager: TagManager
  public readonly locationManager: CameraLocation
  public readonly metadataManager: MetadataManager
  public readonly hardware: CameraHardware
  public readonly streams: CameraStream
  public readonly group: CameraGroup
  public readonly bridge: CameraBridgePivot

  private static loadingCameraAttempt: number = 0
  // public readonly archives: CameraArchive
  // public readonly featureMatrix: CameraFeatureMatrixPivot
  // public readonly heatmap: CameraHeatmapPivot
  // public readonly tracker: CameraTrackerPivot
  // public readonly actionLogs: CameraActionLogsPivot

  protected constructor(cameraObject: CameraObject) {
    this.id = cameraObject.id
    this.base = new CameraBaseInfo(cameraObject.id, cameraObject)
    this.users = new CameraUsers(cameraObject.id)
    this.connection = new CameraConnectionInformation(this, cameraObject)
    this.hardware = new CameraHardware(this, cameraObject)
    this.streams = new CameraStream(this, cameraObject)
    this.record = new CameraRecord(this, cameraObject)
    this.avatar = new CameraAvatar(this)
    this.status = new CameraStatus(this)
    this.thumbnail = new CameraThumbnail(this)
    this.tagManager = new TagManager(this.id, ResourceTypes.Camera)
    this.locationManager = new CameraLocation(this.id)
    this.metadataManager = new MetadataManager(this.id, ResourceTypes.Camera)
    this.group = new CameraGroup(this, cameraObject)
    this.bridge = new CameraBridgePivot(this, cameraObject)
  }

  public static async initById(id: string): Promise<CameraCore> {
    const camera = await this.loadCameraDetail(id)
    return CameraCore.initByCamera(camera)
  }

  public static async initByCamera(camera: CameraObject): Promise<CameraCore> {
    return new CameraCore(camera)
  }

  public static async initTemp(cameraTemp: CameraTemp): Promise<CameraCore> {
    const cameraData = await useServices().camera.create(cameraTemp.cameraData)
    return CameraCore.initByCamera(cameraData)
  }

  async getCameraData() {
    await Promise.all([
      this.status.update(true),
      this.users.load(),
      this.locationManager.loadData(),
      this.avatar.load(),
      this.tagManager.load(),
      this.metadataManager.load()
    ])
  }
  async getCameraBasicData() {
    await Promise.all([this.status.update(true)])
  }

  private static async loadCameraDetail(id): Promise<CameraObject> {
    try {
      if (this.loadingCameraAttempt < 5) {
        this.loadingCameraAttempt++
        return await useServices().camera.find(id)
      } else throw new Error('Many Attempt')
    } catch (e) {
      if (e === 'Not Found') {
        await new Promise((resolve) => setTimeout(resolve, 4_000))
        return await this.loadCameraDetail(id)
      } else throw e
    }
  }

  public async delete(): Promise<string> {
    await this.service.remove(this.id)
    return this.id
  }

  public createTempCamera(): CameraTemp {
    return {
      cameraData: {
        name: this.base.data.name,
        groupId: this.group.groupId,
        config: this.connection.data,
        bridgeId: this.bridge.bridgeId,
        timezone: this.base.data.timezone || undefined,
        userData: CameraCore.generateUniqueId(),
        createdAt: this.base.data.createdAt,
        updatedAt: this.base.data.updatedAt
      },
      tags: this.tagManager.tags.value,
      avatar: this.avatar.avatar.value,
      status: CameraStatusType.offline,
      location: this.locationManager.location
    }
  }

  public static generateUniqueId(): string {
    const time = new Date().getTime()
    const random = Math.floor(Math.random() * 1000000)
    return `${time}-${random}`
  }

  public updateCameraData(camera: CameraObject) {
    this.base.initialCameraData(camera)
    this.connection.initialCameraData(camera)
    this.hardware.initialCameraData(camera)
    this.streams.initialCameraData(camera)
    this.record.updateDependencies(camera as CameraReturnType)
  }
}
