import authenticationEn from '@/assets/lang/en/authentication'
import profileEn from '@/assets/lang/en/profile'
import securityEn from '@/assets/lang/en/security'
import workSpaceEn from '@/assets/lang/en/workspace'
import { permissionsEn } from '@/modules/Permissions/'
import cameraConnectEn from '@/assets/lang/en/onboarding/camera-connect'
import onboardingEn from '@/assets/lang/en/onboarding'
import onboardingBridgeEn from '@/modules/onboarding/lang'
import monitoringEn from '@/assets/lang/en/monitoring'
import pricePeopleEn from '@/assets/lang/en/price-people'
import paymentEn from '@/assets/lang/en/payment'
import billingEn from '@/assets/lang/en/billing'
import cameraDetailEn from '@/assets/lang/en/camera-detail'
import activityLogEn from '@/assets/lang/en/activity-log'
import heatMapEn from '@/assets/lang/en/heat-map'
import ai from '@/assets/lang/en/ai'
import bridgeEn from '@/modules/bridge/lang'
import shortcuts from '@/modules/Shortcuts/lang/index'

export default {
  ...bridgeEn,
  ...heatMapEn,
  ...ai,
  ...activityLogEn,
  ...authenticationEn,
  ...profileEn,
  ...securityEn,
  ...workSpaceEn,
  ...permissionsEn,
  ...onboardingEn,
  ...onboardingBridgeEn,
  ...cameraConnectEn,
  ...monitoringEn,
  ...pricePeopleEn,
  ...paymentEn,
  ...billingEn,
  ...cameraDetailEn,
  ...shortcuts,
  messages: {
    emptyDropdown: 'Enter a free-key tag!',
    expired: 'Expired code.',
    otpError: 'Invalid code.',
    tooManySendSMSNoRemainSec: 'Too many requests, please wait for a few seconds and try again.',
    tooManySendSMS: 'Too many requests, please wait for {seconds} seconds.',
    enableMultiFactor:
      'First, you need to enable your \n' + 'Multi-Factor Authentication By Text Message.',
    enable: 'Enable',
    isRemoved: 'is removed.',
    verified: 'Verified',
    validCode: 'Please enter a valid code.',
    passUpdate: 'Updated!',
    dataNotFound: 'Nothing to show.',
    page: 'Page',
    of: 'of',
    all: 'All',
    checkEmail: 'Check your email now! A verification link has been sent to {email}',
    portError: 'Port must be integer between 1-65535.',
    channelError: 'Secure port must be integer between 1-32.',
    securePortError: 'Secure port must be integer between 0-65535.',
    extraPortError: 'Extra port must be integer between 0-65535.',
    urlError: 'Invalid format.',
    incurrectName: 'Please enter name in 1 to 74 character',
    timezone: 'Please select one of the items in list',
    successfullUpdate: 'Updated successfully',
    removed: 'Removed successfully',
    saved: 'Saved!'
  },
  labels: {
    addCard: 'Add Card',
    cardName: 'Name on Card',
    CommitmentFree: 'Commitment-free zone: bow out anytime without any fees.',
    countryCode: 'Country Code',
    phoneNumber: 'Phone Number',
    password: 'Password',
    fullName: 'Full name',
    zipCode: 'Zip Code',
    email: 'Email',
    numberCameraLabel: 'How many cameras do you own?',
    numberCamera: 'Number Camera',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    verificationCode: 'Enter the verification code',
    by: 'by',
    all: 'All',
    // Active
    //
    // Inactive
    //
    // Deleted
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted',
    online: 'Online',
    offline: 'Offline',
    other: 'other',
    hey: 'Hey {name},',
    invite: 'Invite',
    free: '1 Month Free',
    until: 'Until {date}',
    suspend: 'Suspend'
  },
  passWordValidate: {
    atLeast10: 'MUST contain at least 10 characters',
    oneUpper: 'MUST contain at least one uppercase letter',
    oneNumber: 'MUST contain at least one number',
    oneSpecial: 'MUST contain at least one special character',
    strong: 'MUST contain Strong enough'
  },
  passWordStrength: {
    veryWeak: 'too weak',
    weak: 'weak',
    medium: 'medium',
    strong: 'strong',
    veryStrong: 'too strong'
  },
  logo: {
    altText: 'Arcadian',
    toolTip: 'Arcadian'
  },
  actions: {
    gmail: 'Gmail',
    facebook: 'Facebook',
    linkedin: 'Linkedin',
    sendAgain: 'Send Again',
    logIn: 'Log In',
    signup: 'Sign Up',
    ok: 'Ok',
    cancel: 'Cancel',
    confirm: 'Confirm',
    edit: 'Edit',
    save: 'Save',
    discard: 'Discard',
    back: 'Back',
    verify: 'Verify',
    verifyNow: 'Verify Now',
    unverified: 'Unverified',
    actions: 'Load More',
    addManuallyCamera: 'Add Manually Camera',
    cancelPairing: 'cancel Pairing',
    BetaFeedback: 'Beta Feedback',
    submitTicket: 'Submit Ticket',
    upload: 'Upload',
    undo: '/Undo',
    on: 'ON',
    off: 'OFF',
    help: 'Help',
    loadMore: 'Load More',
    tryAgain: 'Try Again',
    goToMonitoring: 'Go To Monitoring',
    delete: 'Delete',
    deleteCamera: 'Delete Camera',
    backTOMonitoring: 'Back to Monitoring',
    contactUs: 'Contact Us',
    checkoutAIStore: 'Checkout AI Store',
    pauseUpcomingBills: 'Pause Upcoming Bills'
  },
  errorMessage: {
    error: '404: PARDON THE INTERRUPTION',
    InvalidDriver: 'hey, you sure you have the right brand? That looks like an RTSP link.',
    removeWorkspace: 'This workspace has been removed',
    updateSubscription: 'Well done! Your new plan has been saved successfully',
    successfulRemoveSeat:
      'Enjoy your savings! Plan downgrade is confirmed. Future invoices are available in Workspace Billing.',
    RemoveLastSeat:
      "{'Welcome to a seamless plug-and-play experience! Your exclusive virtual camera seat is permanently yours, a testament to our commitment. Questions? Reach out directly at sales@arcadian.ai.'}",
    zipCodeWrong: 'Invalid.',
    waitForDoActions: "We're saving your changes. ",
    SubscriptionProblem:
      "hey, we've noticed a subscription issue. Please add a valid payment method. If the problem persists, contact us at +1 (800) 288-9192 or sales{'@'}arcadian.ai. We're here to help!",
    cameraDataWrong: "hey, It looks like you'll need to configure your camera ports.",
    failTrial: "Oh dear! You've hit a space timeout. Tap to pay and dive back into play.",
    refresh: 'Oops! Try refreshing your screen or reach out to us.',
    closeLastTab: 'Unable to close latest tab.',
    canNotRemoveWorkspace:
      'Planning to leave? Please get in touch with our support team to initiate your data deletion process.',
    workspaceSecurity: 'Workspace Security',
    newDeviceConnected: 'New Device Connected',
    changeDevice: 'Switch to {data}',
    stayDevice: 'Stay on my {data}',
    help: 'Help',
    BackToMonitoring: 'Back to Monitoring',
    notAvailable: 'PAGE NOT AVAILABLE',
    invalid: 'You either don’t have permissions to view or the link is invalid.',
    goBack: 'Go back',
    passwordWrong: "Oops! Password doesn't match our records.",
    AddNewPaymentMethods: 'Saved!',
    createSubscription: 'All Set!',
    noBillingAddress:
      'Hello!\n To calculate your totals, please ensure you have a valid payment method on file. Alternatively, feel free to use our pricing calculator available on our website.',
    oop: 'Oop! Something went wrong.',
    matchesName: 'Enter your first name, followed by your last name.',
    updateNumberDuplicate: 'Number already taken. Select a new one or contact us.',
    invalidCode: 'Enter a valid code.',
    interrupt: {
      title: '404: CONNECTION HICCUP DETECTED',
      refresh1: "Something's amiss! ",
      refresh2: 'Ensure your internet is on and tap refresh.',
      back: 'Back to Monitoring'
    },
    stripe: {
      callSales:
        "Try again. Looks like there’s an issue with your payment. For help, email sales{'@'}arcadian.ai",
      card: {
        card_declined: 'The card was declined for an unknown reason.',
        generic_decline:
          'The card was declined for an unknown reason or Stripe Radar blocked the payment.',
        insufficient_funds: 'The card has insufficient funds to complete the purchase.',
        lost_card: 'The payment was declined because the card is reported lost.',
        stolen_card: 'The payment was declined because the card is reported stolen.',
        card_velocity_exceeded:
          'You have exceeded the balance, credit limit, or transaction amount limit available on your card.',
        expired_card: 'The card has expired.',
        incorrect_cvc: 'The CVC number is incorrect.',
        processing_error: 'An error occurred while processing the card.',
        incorrect_number: 'The card number is incorrect.'
      }
    },
    fillRequiredFields: 'Please fill the required fields!!!',
    timeRangeBadOrder: '* Stay within a 24-hour timeframe.',
    timeRangeEqual: '* Please select a valid range of times.'
  },
  timeRangeBadOrder: '* Stay within a 24-hour timeframe.',
  twitterFollow: 'Follow for updates',
  twitterUpdate: 'X Updates',
  navigation: {
    newSpace: 'New Space',
    monitoring: 'Monitoring',
    Clips: 'Clips',
    People: 'People',
    invite: 'Invite',
    workspace: 'Workspace',
    Billing: 'Billing & Invoicing',
    spaceOverview: 'Space Overview',
    codeRedLock: 'Code Red Lock',
    Connect: ' Connect',
    dayFree: ' 1 Month Free',
    freeTrial: '1 Month Free',
    account: ' Account',
    security: ' Security',
    myprofile: ' My Profile',
    theme: 'Theme',
    LogOut: ' Log Out',
    shortcuts: 'Shortcuts'
  },
  notifications: {
    notifications: 'Notifications',
    clickhere: 'click here.',
    LandonSecurity: 'Show me',
    Clips: 'Show me',
    LogIn: 'Show me',
    UserDetails: 'Show me',
    PeopleLanding: 'Show me',
    RoleDetails: 'Show me',
    camera: 'Show me',
    WorkspaceBillingLanding: 'Show me'
  },
  hello: 'Hello',
  loading: 'Loading Data ...',
  noData: 'Nothing to show.',
  tabMenu: {
    rename: 'Rename',
    close: 'Close',
    share: 'Share',
    monitoring: 'Monitoring',
    profileSettings: 'My Profile',
    permissionSettings: 'People',
    permissions: 'Permissions',
    people: 'People',
    myPlan: 'My Plan',
    roles: 'Roles',
    securitySettings: 'Security',
    billingSettings: 'Billing',
    tutorial: 'How-To Videos',
    clips: 'Clips',
    AiHeatMap: 'AI Heatmaps',
    workspaceSettings: 'Workspace Overview',
    recents: 'Recents',
    quickLinks: 'Quick Links',
    newSpace: 'New Space',
    newRole: 'New Role',
    result: 'Results',
    connect: 'Connect',
    invite: 'Invite',
    invoice: 'Invoices',
    cameraConnect: 'Connect Cameras',
    onboarding: 'Onboarding',
    bridgeOnboarding: 'Onboarding',
    paymentMethod: 'Payment Methods',
    passwordRecovery: 'Password Recovery',
    passwordUpdate: 'Password Update',
    download: 'Download',
    snapshots: 'Snapshots',
    exports: 'Exports',
    activityHotspots: 'Activity Hotspots',
    liveStreaming: 'Live Streaming',
    allDevices: 'All Devices',
    listView: 'List View',
    RewindDate: 'Rewind to Specific Date',
    billing: 'Billing',
    upcomingPayments: 'Upcoming Payments',
    subscription: 'Subscription',
    upgradeDowngrade: 'Upgrade/Downgrade',
    loggedDevices: 'Logged in Devices',
    multiFactorAuthentication: 'Multi-factor Authentication',
    resetPassword: 'Reset Password',
    newPassword: 'New Password',
    emailVerification: 'Email Verification',
    PhoneVerification: 'Phone No. Verification',
    newPhoneNo: 'New Phone No.',
    newEmail: 'New Email',
    myTimezone: 'My Timezone',
    themes: 'Themes',
    playback: 'Rewind to Date',
    logs: 'Logs',
    activityLogs: 'Activity Logs',
    userLogs: 'User Logs',
    auditTrails: 'Audit Trails',
    shortcuts: 'Shortcuts'
  },
  welcomeBeta: 'Welcome to Beta!',
  advanceHorizontal: 'Advanced feature sets on the horizon.',
  betaFeedBack: 'Beta Feedback',
  deniedTag: "You can't add this value",
  ok: 'Ok'
}
