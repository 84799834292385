import { type Ref, ref } from 'vue'
import { ImagePreview } from '@/player/lib/preview/image-preview'
import type { CameraCoreComposite } from '@/modules/camera-new/interface'
import { CameraCore } from '@/modules/camera-new/CameraCore'

/**
 * Manages the loading and retrying mechanism for camera thumbnails.
 */
export class CameraThumbnail implements CameraCoreComposite {
  public thumbnail: Ref<string> = ref('')
  private imagePreview!: ImagePreview
  private attemptLoadCount = 0
  public readonly id: string

  /**
   * Constructor to initialize the ThumbnailManager class with a CameraManager instance.
   * @param {CameraCore} core - An instance of CameraManager to manage camera operations.
   */
  constructor(public readonly core: CameraCore) {
    this.id = this.core.id
    this.imagePreview = new ImagePreview(this.id)
  }

  /**
   * Asynchronously loads the thumbnail for a camera, with retry logic for failed attempts.
   */
  public async loadThumbnail(): Promise<void> {
    try {
      this.thumbnail.value = await this.attemptLoadThumbnail()
    } catch (e: any) {
      await this.retryLoadThumbnail()
    }
  }

  /**
   * Resets the thumbnail property and load attempt counter.
   */
  public reset() {
    this.thumbnail.value = ''
    this.attemptLoadCount = 0
  }

  /**
   * Protected method to attempt loading the live thumbnail.
   * @returns {Promise<string>} A promise that resolves with the thumbnail image URL.
   */
  protected async attemptLoadThumbnail(): Promise<string> {
    this.imagePreview.maskData = this.core.base.data.maskData
    return this.imagePreview.live()
  }

  /**
   * Loads a thumbnail from the archive for a specific date.
   * @param {Date} date - The date for which to load the archived thumbnail.
   * @returns {Promise<string>} A promise that resolves with the archived thumbnail URL.
   */
  public async loadArchiveThumbnail(date: Date): Promise<string> {
    return this.imagePreview.archive(date)
  }

  /**
   * Retries loading the thumbnail with exponential backoff strategy.
   */
  protected async retryLoadThumbnail(): Promise<void> {
    // Limit the retry attempts to 5.
    if (this.attemptLoadCount < 5) {
      await this.delay(2000)
      this.attemptLoadCount++
      await this.loadThumbnail()
    }
  }

  /**
   * Creates a delay.
   * @param {number} ms - The delay in milliseconds.
   * @returns {Promise<void>} A promise that resolves after the specified delay.
   */
  protected delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
}
