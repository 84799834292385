const clickThreshold = 200

export class MouseEventsHelpers {
  protected isActive = false
  protected activaAt = 0
  protected handler = ''
  protected initialTouch = { x: 0, y: 0 }

  get context() {
    return this.handler
  }

  constructor(
    protected el: HTMLElement,
    public dragCallback?: (dx: number, dy: number, x: number, y: number, handel: string) => string,
    public hoverCallback?: (x: number, y: number) => void,
    public mouseOutCallback?: () => void,
    public clickCallback?: (x: number, y: number) => void
  ) {
    this.initializeDragEvents()
  }

  protected initializeDragEvents() {
    this.el.addEventListener('mousedown', this.handleMouseDown)
    this.el.addEventListener('mousemove', this.handleMouseMove)
    this.el.addEventListener('touchstart', this.handleTouchStart, { passive: false })
    this.el.addEventListener('touchmove', this.handleTouchMove, { passive: false })
    this.el.addEventListener('click', this.handelClick)

    window.addEventListener('mouseup', this.handleMouseUp)
    window.addEventListener('mousemove', this.handleGlobalMouseMove)
    window.addEventListener('touchend', this.handleTouchEnd, { passive: false })

    if (this.mouseOutCallback) {
      this.el.addEventListener('mouseout', this.mouseOutCallback)
      document.addEventListener('mouseleave', this.mouseOutCallback)
    }
  }

  protected handleMouseDown = () => {
    this.handler = 'timeline'
    this.isActive = true
    this.activaAt = Date.now()
  }

  protected handleMouseMove = (event: MouseEvent) => {
    if (this.hoverCallback) {
      this.hoverCallback(event.offsetX, event.offsetY)
    }
  }

  protected handleGlobalMouseMove = (event: MouseEvent) => {
    if (!this.isActive) return
    if (this.dragCallback) {
      if (Date.now() - this.activaAt > clickThreshold) {
        this.handler = this.dragCallback(
          event.movementX,
          event.movementY,
          event.offsetX,
          event.offsetY,
          this.handler
        )
      }
    }
  }

  protected handleMouseUp = () => {
    this.isActive = false
    this.handler = ''
  }

  protected handleTouchStart = (event: TouchEvent) => {
    this.handleMouseDown()
    if (event.touches.length === 1) {
      this.initialTouch.x = event.touches[0].clientX
      this.initialTouch.y = event.touches[0].clientY
    }
  }

  protected handleTouchMove = (event: TouchEvent) => {
    if (!this.isActive || event.touches.length > 1) return

    if (this.dragCallback) {
      const touch = event.touches[0]
      const dx = touch.clientX - this.initialTouch.x
      const dy = touch.clientY - this.initialTouch.y

      this.initialTouch.x = touch.clientX
      this.initialTouch.y = touch.clientY
      const rect = this.el.getBoundingClientRect()
      const offsetX = touch.pageX - rect.left
      const offsetY = touch.pageY - rect.top
      this.handler = this.dragCallback(dx, dy, offsetX, offsetY, this.handler)
    }
    event.preventDefault()
  }

  protected handelClick = (event: MouseEvent) => {
    if (this.clickCallback) {
      if (Date.now() - this.activaAt <= clickThreshold) {
        this.clickCallback(event.offsetX, event.offsetY)
      }
    }
  }

  protected handleTouchEnd = (event: TouchEvent) => {
    if (event.touches.length === 0) {
      this.handleMouseUp()
    }
  }

  public cleanup() {
    this.el.removeEventListener('mousedown', this.handleMouseDown)
    this.el.removeEventListener('mousemove', this.handleMouseMove)
    this.el.removeEventListener('touchstart', this.handleTouchStart)
    this.el.removeEventListener('touchmove', this.handleTouchMove)
    this.el.removeEventListener('click', this.handelClick)

    window.removeEventListener('mouseup', this.handleMouseUp)
    window.removeEventListener('mousemove', this.handleGlobalMouseMove)
    window.removeEventListener('touchend', this.handleTouchEnd)
  }
}
