import { Codec, FrameTypes } from '@/player/types'
import type { HeaderObject } from '@/player/interfaces'
import { Buffer } from 'buffer'
import { PlayerApis } from '@/player/lib/player/player-apis'
import { getDirectDecoder } from '@/player/lib/decoder/decoder-queue'
import YUVCanvas from 'yuv-canvas'
import type { YUVBuffer } from 'yuv-buffer'
import { MaskLocal } from '@/player/lib/mask/mask-local'

export class ImagePreview {
  protected readonly mimeTypeByCoded: Record<string, Codec> = {
    'image/jpeg': Codec.mJPEG,
    'video/mpeg4': Codec.mPEG4,
    'video/h264': Codec.h264,
    'video/h265': Codec.h265
  }

  constructor(public readonly id: string, public maskData?: Array<Array<number>>) {}

  protected parseType(type: string): Codec {
    const codec = this.mimeTypeByCoded[type]
    if (codec !== undefined) {
      return codec
    } else throw new Error('unsupported codec!')
  }

  protected makeFrame(data: ArrayBuffer, codec: Codec): HeaderObject {
    return {
      type: FrameTypes.VideoHeader,
      data: Buffer.from(data),
      date: 0,
      streamIndex: 0,
      streamCodec: codec,
      isKey: true
    }
  }

  protected async decode(frame: HeaderObject) {
    const images = await getDirectDecoder().decode(frame)
    if (images.length === 0) {
      // one time retry
      const images = await getDirectDecoder().decode(frame)
      return images[0]
    }
    return images[0]
  }

  protected convertImageToDataURL(image: YUVBuffer, type: string) {
    const canvas = document.createElement('canvas')
    const ctx = YUVCanvas.attach(canvas, {
      webGL: false
    })
    ctx.drawFrame(image)
    if (this.maskData) {
      const realCtx = canvas.getContext('2d')
      if (realCtx) {
        const mask = new MaskLocal()
        mask.setCtx(realCtx)
        mask.setRenderSize(image.format.width, image.format.height)
        mask.setMaskData(this.maskData)
        mask.draw()
      }
    }
    return canvas.toDataURL(type)
  }

  protected async generate(type: string, data: ArrayBuffer, imageFormat: string) {
    const codec = this.parseType(type)
    const frame = this.makeFrame(data, codec)
    const image = await this.decode(frame)
    image.format.displayWidth = image.format.width
    image.format.displayHeight = image.format.height
    return this.convertImageToDataURL(image, imageFormat)
  }

  async live(imageFormat = 'image/png') {
    const { type, data } = await PlayerApis.getCameraThumbnail(this.id)
    return this.generate(type, data, imageFormat)
  }

  async archive(date: Date | number, imageFormat = 'image/png') {
    if (typeof date === 'number') {
      date = new Date(date)
    }
    const { type, data } = await PlayerApis.getCameraThumbnailArchive(this.id, date)
    return this.generate(type, data, imageFormat)
  }
}
